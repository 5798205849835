function CopyButton({setClick ,click}){
    return(
        <button 
        className="copy-button" 
        
        onClick={click ? (e) => setClick(click) : undefined}
        >
        <svg  viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="copy-svg" >
          <path d="M18.5 12.379H29.375C31.1223 12.379 31.9959 12.379 32.6851 12.6617C33.6039 13.0386 34.3339 13.7616 34.7145 14.6716C35 15.354 35 16.2192 35 17.9496C35 19.6799 35 20.5451 34.7145 21.2276C34.3339 22.1376 33.6039 22.8605 32.6851 23.2374C31.9959 23.5201 31.1223 23.5201 29.375 23.5201C27.6277 23.5201 26.7541 23.5201 26.0649 23.8028C25.1461 24.1797 24.4161 24.9027 24.0355 25.8126C23.75 26.4951 23.75 27.3603 23.75 29.0907C23.75 30.821 23.75 31.6862 23.4645 32.3687C23.0839 33.2786 22.3539 34.0016 21.4351 34.3785C20.7459 34.6612 19.8723 34.6612 18.125 34.6612C16.3777 34.6612 15.5041 34.6612 14.8149 34.3785C13.8961 34.0016 13.1661 33.2786 12.7855 32.3687C12.5 31.6862 12.5 30.821 12.5 29.0907V18.321C12.5 16.2411 12.5 15.2012 12.9087 14.4068C13.2683 13.708 13.8419 13.1399 14.5475 12.7838C15.3497 12.379 16.3998 12.379 18.5 12.379Z" fill="#1E2330"/>
          <path d="M34.7145 21.2276C35 20.5451 35 19.6799 35 17.9496C35 16.2192 35 15.354 34.7145 14.6716C34.3339 13.7616 33.6039 13.0386 32.6851 12.6617C31.9959 12.379 31.1223 12.379 29.375 12.379H18.5C16.3998 12.379 15.3497 12.379 14.5475 12.7838C13.8419 13.1399 13.2683 13.708 12.9087 14.4068C12.5 15.2012 12.5 16.2411 12.5 18.321V29.0907C12.5 30.821 12.5 31.6862 12.7855 32.3687C13.1661 33.2786 13.8961 34.0016 14.8149 34.3785C15.5041 34.6612 16.3777 34.6612 18.125 34.6612C19.8723 34.6612 20.7459 34.6612 21.4351 34.3785M34.7145 21.2276C34.3339 22.1376 33.6039 22.8605 32.6851 23.2374C31.9959 23.5201 31.1223 23.5201 29.375 23.5201C27.6277 23.5201 26.7541 23.5201 26.0649 23.8028C25.1461 24.1797 24.4161 24.9027 24.0355 25.8126C23.75 26.4951 23.75 27.3603 23.75 29.0907C23.75 30.821 23.75 31.6862 23.4645 32.3687C23.0839 33.2786 22.3539 34.0016 21.4351 34.3785M34.7145 21.2276C32.894 27.4463 28.0422 32.3444 21.7988 34.2665L21.4351 34.3785M27.5 12.379L27.5 10.8936C27.5 8.81371 27.5 7.77377 27.0913 6.97937C26.7317 6.2806 26.1581 5.71247 25.4525 5.35643C24.6503 4.95166 23.6002 4.95166 21.5 4.95166H11C8.8998 4.95166 7.8497 4.95166 7.04754 5.35643C6.34193 5.71247 5.76825 6.2806 5.40873 6.97937C5 7.77377 5 8.8137 5 10.8936V21.2919C5 23.3718 5 24.4117 5.40873 25.2061C5.76825 25.9049 6.34193 26.473 7.04754 26.829C7.8497 27.2338 8.90017 27.2338 11.0011 27.2338H12.5" stroke="#1E2330"strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        Copy
      </button>
    );
}
export default CopyButton