// import img7 from "assets/sendForm.svg";
import Arrow3 from "../../HomePageSvg/Arrow3";

function FifthImage ({setClick}) {
    return(
    <div className="header-img-container">
        <img
          className="content-img clickable"
          onClick={(e) => setClick(6)}
          src={"https://forms-easy.com/assets/sendForm.svg"}
          alt="send form"
        />
       <div className="div2">
       <div className="click-form">Click to Submit ✅</div>
      <Arrow3/>
       </div>
    </div>
    )
}
export default FifthImage;