import Logo from "assets/lastLogo.svg";

function Nav ({getStarted}) {
    return(
        <div className="nav">
        <img src={Logo} alt="forms easy" className="logo" />
        <button onClick={getStarted} style={{ cursor: "pointer" }}>
          Get Started
        </button>
      </div>
    )
}
export default Nav