import React from 'react';
import styled, { css } from 'styled-components';

// Use shouldForwardProp to filter out unknown props
const Wrap = styled.div.withConfig({
  shouldForwardProp: (prop) => !['horizontal', 'vertical', 'severity'].includes(prop),
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  pointer-events: none;

  ${(p) => {
    switch (p.horizontal) {
      case 'left':
        return css`
          justify-content: flex-start;
        `;
      case 'right':
        return css`
          justify-content: flex-end;
        `;
      default:
        return css`
          justify-content: center;
        `;
    }
  }}

  ${(p) => {
    switch (p.vertical) {
      case 'center':
        return css`
          align-items: center;
        `;
      case 'top':
        return css`
          align-items: flex-start;
        `;
      default:
        return css`
          align-items: flex-end;
        `;
    }
  }}
  transition: all 0.1s 0.3s ease;
  ${(props) =>
    props.open
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}

  .snackbar {
    padding: 15px 25px;
    border-radius: 4px;
    min-width: 260px;

    ${(props) => {
      switch (props.severity) {
        case 'alert':
          return css`
            background-color: ${(props) => props.theme.color.redBg};
            color: ${(props) => props.theme.color.red};
            outline: ${(props) => props.theme.color.red}11 1px solid;
          `;
        case 'success':
          return css`
            background-color: ${(props) => props.theme.color.greenBg};
            color: ${(props) => props.theme.color.green};
            outline: ${(props) => props.theme.color.green}11 1px solid;
            svg {
              fill: ${(props) => props.theme.color.green};
            }
          `;
        default:
          return css`
            background: ${(props) => props.theme.color.blueBg};
            color: ${(props) => props.theme.color.blue};
            outline: ${(props) => props.theme.color.blue}11 1px solid;
          `;
      }
    }}

    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    ${(props) =>
      props.open
        ? css`
            opacity: 1;
            transform: translate(0, 0);
          `
        : css`
            opacity: 0;
            transform: translate(0, 200px);
            transition: all 0.9s cubic-bezier(0, 0, 0.2, 1);
          `}
  }
`;

function Snackbar({ open = false, onClose, children, ...rest }) {
  return (
    <Wrap open={open} {...rest}>
      <div className="snackbar">{children}</div>
    </Wrap>
  );
}

export default Snackbar;
