import { useEffect, useState } from "react";
import { useApi } from "./provider";
import { Box, Button, Card, Icon, TableContainer } from "ui";
import Dropdown from "ui/core/Dropdown";
import Add from "./Add";
import ShowDialog from "./ShowDialog";
import DeleteDialog from "./DeleteDialog";
import Dialog from "ui/Dialog";
import Edit from "./Edit";

const cols = [
  { name: "name", label: "Name" },
  { name: "createDate", label: "Creation Date", fn: "datetime" },
  {
    name: "enabled",
    label: "Enabled",
    render: ({ enabled }) => (
      <Box sx={{ ta: "center" }}>
        <Icon
          name={enabled ? "check" : "xmark"}
          w={enabled ? 14 : 11}
          c={enabled ? "green" : "gray600"}
        />
      </Box>
    ),
  },
];

export default function List() {
  const [openAdd, setOpenAdd] = useState(0);
  const [openDelete, setOpenDelete] = useState(0);
  const [openShow, setOpenShow] = useState(0);
  const [openEdit, setOpenEdit] = useState(0);

  const {
    state: { list, status, page, perPage },
    fetchList,
    dispatch,
  } = useApi();

  useEffect(() => {
    const getList=async(e) => {  
      try {
      await fetchList({ page, perPage });
      } catch (e) {
      console.log(e);
      }
    };
    getList();
  }, [fetchList, page, perPage]);

  return (
    <Box>
      <Box sx={{ mb: 20, py: 7, border: ["gray300", 1, "solid", 0, "tb"] }}>
        <Dropdown
          space={2}
          show={openAdd}
          setShow={setOpenAdd}
          content={
            <Card shadow elevation={10} padding="0">
              <Add onSuccess={() => setOpenAdd(0)} />
            </Card>
          }
        >
          <span>
            <Button
              size="small"
              variant="text"
              iconName="plus"
              sx={{ text: "uppercase" }}
            >
              Create API Key
            </Button>
          </span>
        </Dropdown>
      </Box>
      <TableContainer
        empty={status === "idle" && !list.length}
        status={status}
        cols={cols}
        data={list}
        compact={true}
        moreOptions={[
          {
            name: "show",
            render: ({ id }) => (
              <Button
                size="small"
                variant="text"
                type="button"
                sx={{ fw: 700, fs: 13 }}
                onClick={() => {
                  setOpenShow(id);
                  dispatch({
                    type: "set_selected",
                    payload: list.find((item) => item.id === id),
                  });
                }}
              >
                SHOW KEY
              </Button>
            ),
          },
          {
            name: "edit",
            render: ({ id }) => (
              <Icon
                name="pen"
                w={14}
                hover="primaryBg"
                c="primary"
                onClick={() => {
                  setOpenEdit(id);
                  dispatch({
                    type: "set_selected",
                    payload: list.find((item) => item.id === id),
                  });
                }}
              />
            ),
          },
          {
            name: "delete",
            render: ({ id }) => (
              <Icon
                name="trash"
                w={14}
                hover="redBg"
                c="red"
                onClick={() => setOpenDelete(id)}
              />
            ),
          },
        ]}
      />

      <ShowDialog {...{ openShow, setOpenShow }} />

      <DeleteDialog {...{ openDelete, setOpenDelete }} />

      <Dialog show={openEdit} onClose={() => setOpenEdit(0)}>
        <Edit onSuccess={() => setOpenEdit(0)} />
      </Dialog>
    </Box>
  );
}
