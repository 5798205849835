import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "services/auth";
import { Box, Button, Type } from "ui";

export default function ResendEmail({initCounter = 15}) {
  const auth = useAuth();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({});

  const [counter, setCounter] = React.useState(initCounter);
  const [statusMessage, setStatusMessage] = useState("");

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const onSubmit = async () => {
    try {
      const res=await auth.resendVerification(JSON.parse(localStorage.getItem("user"))?.user);
      if (res.status === "success") {
        setStatusMessage(
          res.message
        );
      } else setStatusMessage(res.message);
    } catch (e) {
      window.scrollTo(0, 0);
      console.log("error", e);
    }
  };

  return (
    <Box
      as="form"
      sx={{ w:["600px","_","90%","_"], m: "20px auto", ta: "center" }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Type var="h5" sx={{ mb: 15 }}>
        Resend verification email
      </Type>
      <Button
        type="submit"
        loading={isSubmitting}
        sx={{ w: 300 }}
        disabled={Boolean(counter)}
      >
        {counter || "Resend"}
      </Button>
      <Type>{statusMessage}</Type>
    </Box>
  );
}
