import { useSubmissionForm } from "./provider";
import { useEffect, useState } from "react";
import ProjectForm from "views/project/form";
import Modal from "ui/Modal";
import { Box, Flex, Icon, Loading, Pagination, Slider, Tooltip, Type } from "ui";
import { useProject } from "views/project/provider";
import FormCard from "./formList/FormCard";
import ProjectsList from "./formList/ProjectsList";
import DeleteFormDialog from "./formList/DeleteFormDialog";
import Dropdown from "ui/core/Dropdown";

function FormsList() {
  const {
    state: { forms ,count,page,limit,status},
    fetchList: fetchForms,
    dispatch
  } = useSubmissionForm();

  const [createProject, setcreateProject] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const {
    state: {list: projects, selected: project },
    dispatch:dispatchProject
  } = useProject();
   
  useEffect(() => {
    if(!project && projects.length>0){
      dispatchProject({ type: "set_selected", payload: projects[0] });
    }
  }, [dispatchProject,projects,project]);


  useEffect(() => {
    const getForms=async(e) => {  
      try {
      await   fetchForms(page, limit, project.id);
      } catch (e) {
      console.log(e);
      }
    };
    if (!project) return;
    getForms();
  }, [fetchForms, project,page,limit]);

  if (!project) return;

  const handleChangePage = (newPage) =>
    dispatch({ type: "set_page", payload: newPage });

  return (
    <>
     {status === "fetching" && (
        <Flex>
          <Loading />
        </Flex>
      ) }
      <Box sx={{ p: 20 }}>
        <Type var="h4" sx={{ d: ["none", "_", "_", "block"], mb: 20 }}>
          All Forms
        </Type>
        <Flex jc="space-between" gap={20} sx={{ mb: 30 }}>
          <Type var="h4" sx={{ d: ["block", "_", "_", "none"] }}>
            All Forms
          </Type>

          <Flex gap={15}>
            <ProjectsList />
            <Dropdown
              content={<Tooltip>Add New Project</Tooltip>}
              click={false}
              space={-6}
              delay={0.4}
            >
              <Box onClick={() => setcreateProject(true)} sx={{ lh: 0 }}>
                <Icon name="plus" hover="primary" w={18} rounded c="primary" />
              </Box>
            </Dropdown>
          </Flex>
        </Flex>

        {forms.list.length !== 0 ? (
          <>
            <Slider
              slides={forms.list.map((form, i) => (
                <FormCard
                  id={"card-" + i}
                  key={form.id}
                  {...{
                    setOpenDeleteDialog,
                    form,
                    projectId: forms.projectId,
                  }}
                />
              ))}
              totalView={3}
            />
          <Pagination
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          />
          </>
        ) : (
          <Flex sx={{ py: 60 }}>
            <Type var="subtitle" sx={{ c: "gray600", fs: 14 }}>
              There is no forms in this project!
            </Type>
          </Flex>
        )}
      </Box>
    
      <Modal onClose={() => setcreateProject(false)} show={createProject}>
        <ProjectForm
          setcreateProject={setcreateProject}
          onBack={() => setcreateProject(false)}
          onSuccess={() => {
            window.scrollTo({
              top: 200,
              left: 0,
              behavior: "smooth",
            });
            setcreateProject(false);
          }}
        />
      </Modal>

      <DeleteFormDialog
        {...{
          setOpenDeleteDialog,
          openDeleteDialog,
          projectId: forms.projectId,
        }}
      />
    </>
  );
}

export default FormsList;
