import { useCallback, useState } from "react";
import { Flex, InputRadio, InputText, Type } from "ui";

import {
  FlexStyled,
  StyledBox,
  AlignedButton,
  SecondRowItem,
} from "../../../Components";

function GeneralSettings({ edit, selectedForm }) {
  const [error, setError] = useState([]);
  const [checkEnableForm, setCheckEnableForm] = useState(selectedForm?.enabled);

  const [description, setDescription] = useState(selectedForm?.description);
  const [name, setName] = useState(selectedForm?.name);

  const handleInputNameChange = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleInputDescriptionChange = useCallback((event) => {
    setDescription(event.target.value);
  }, []);

  const handleEdit =async(e) => {

    try {
      e.preventDefault();
      setError([])
      const data = {
        name: name,
        enabled: checkEnableForm,
      };
      description === "" ?data.description=null:data.description=description
      await edit(data);
    } catch (e) {
      console.log("error", e);
      if (e.status === 400) setError([e.data.message]);
    }
  };

  return (
    <>
      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Form Name</Type>
          <Type variant="subtitle">Only visible to you</Type>
        </StyledBox>
        <SecondRowItem>
          <InputText
            placeholder={selectedForm?.name}
            size="medium"
            sx={{ mb: 0 }}
            value={name}
            onChange={handleInputNameChange}
          />
        </SecondRowItem>
      </FlexStyled>


      <FlexStyled style={{ justifyContent: "flex-start" }}>
        <InputRadio
          toggleButton={true}
          checked={checkEnableForm}
          inputProps={{
            checked: checkEnableForm,
            onChange: () => setCheckEnableForm(!checkEnableForm),
          }}
        />
        <StyledBox>
          <Type>Enable form</Type>
          <Type variant="subtitle">
            Form status, disable this to close your form for new messages.
          </Type>
        </StyledBox>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Description</Type>
          <Type variant="subtitle">Only visible to you</Type>
        </StyledBox>
        <SecondRowItem>
          <InputText
            type="textarea"
            value={description ?? ""}
            placeholder={selectedForm?.description}
            sx={{
              mb: "0px",
            }}
            onChange={handleInputDescriptionChange}
          />
        </SecondRowItem>
      </FlexStyled>

      <AlignedButton onClick={(e) => handleEdit(e)}>Save</AlignedButton>
      <Flex
        role="alert"
        sx={{
          d: error.length ? "block" : "none",
          p: 5,
          bgc: "redBg",
          w: "100%",
          border: ["red", 10, "solid", 5, "r"],
          my: 10,
        }}
      >
        {error.map((err,index) => (
          <Type key={index}>{err}</Type>
        ))}
      </Flex>
    </>
  );
}
export default GeneralSettings;
