function Arrow3 (){
    return(
        <svg
        className="arrow"
        width="108"
        height="114"
        viewBox="0 0 108 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2671_6214)">
          <path
            className="squiggle2"
            stroke="black"
            strokeMiterlimit="1"
            strokeWidth="1"
            d="M68.5421 57.8895C67.1907 73.7481 52.7712 85.6753 30.028 89.9846C31.9245 86.3089 37.8687 85.8994 37.3707 80.8542C36.7819 80.4493 36.3592 79.9758 36.1249 80.0208C30.2988 82.644 24.4282 85.1754 18.7693 88.0147C18.3022 88.2035 17.8925 88.5109 17.5807 88.9067C17.269 89.3024 17.0659 89.7727 16.9917 90.271C16.9174 90.7692 16.9746 91.2783 17.1575 91.7477C17.3403 92.2171 17.6426 92.6307 18.0343 92.9474C21.8174 96.2323 25.5395 99.7305 31.2964 101.784L29.9423 95.6407C36.3953 93.828 42.9564 92.6059 49.0759 90.2375C63.4678 84.6556 72.9212 74.4838 75.3947 58.7835C75.7798 56.2968 77.0523 55.2196 79.0302 54.0451C82.9996 51.8474 86.3647 48.703 88.8262 44.8916C91.2877 41.0801 92.7695 36.7195 93.1399 32.1974C93.4921 28.7065 93.678 24.876 89.101 21.9129C89.8931 24.7552 90.1169 27.7258 89.7595 30.6547C89.4021 33.5836 88.4706 36.4133 87.0183 38.9817C84.3038 43.9875 80.81 48.1746 75.467 50.2414C72.1989 46.1795 69.5439 41.9225 65.9653 38.7086C62.297 35.4511 58.1355 32.7956 53.6357 30.8412C51.6312 29.9287 49.3669 29.7609 47.2498 30.3679C45.1326 30.9748 43.3012 32.3168 42.0845 34.1527C39.6705 37.6275 39.9268 42.0726 43.153 46.0804C48.0894 52.1628 55.1571 56.1366 62.9188 57.1937C64.7967 57.5647 66.6762 57.6886 68.5421 57.8895ZM68.2437 52.3078C64.5177 52.3971 60.816 51.6868 57.3877 50.2249C53.9594 48.7629 50.8842 46.5832 48.3694 43.8326C46.6879 42.0709 45.2949 39.9565 47.151 37.5941C48.7464 35.573 51.0594 36.0557 53.0137 36.972C59.7423 40.1327 65.128 45.5827 68.2085 52.3483L68.2437 52.3078Z"
            fill="#1E2330"
          />
        </g>
        <defs>
          <clipPath id="clip0_2671_6214">
            <rect
              width="109.089"
              height="47.5432"
              fill="white"
              transform="matrix(-0.655647 0.755068 0.755068 0.655647 71.7109 0.0322266)"
            />
          </clipPath>
        </defs>
      </svg>
    )
}
export default Arrow3;