import Layout from "layout/Layout";
import { useEffect, useState } from "react";
import { Flex, Icon, Loading, TableContainer } from "ui";
import { useAdmin } from "../provider";


function Users () {
  const [list,setList] = useState([]);
  const [confirm,setConfirm] = useState({})
  const {
      state: { usersList },
      FetchUsersList,
      deleteUser
    } = useAdmin();
  
    useEffect(() => {
      const getUsersList=async(e) => {  
        try {
        await FetchUsersList();
        } catch (e) {
        console.log(e);
        }
      };
      getUsersList();
    }, [FetchUsersList]);

    useEffect(()=>{
      setList(usersList.map(item => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`
      })))
    },[usersList])
    
    const DeleteUser = (id) => {
      const result = window.confirm("Are you sure you want to delete this user?");
      if (result) {
        setConfirm((prevConfirm) => ({
          ...prevConfirm,
          [id]: true,
        }));
        deleteUser(id)
      }
    };
    const columns = [
      {
        label: "Name",
        name: "fullName",
        style: { textAlign: "center", fontWeight: "600", fontSize: "12px" },
      },
      {
        label: "Email",
        name: "primaryVerifiedEmail",
        style: { textAlign: "center", fontWeight: "600", fontSize: "12px" },
  
      },
      {
        label: "Date",
        name: "createDate",
        style: { textAlign: "center", fontWeight: "600", fontSize: "12px" },
        
      },
      {
        label: "Number of form",
        name: "Number of form",
        style: { textAlign: "center", fontWeight: "600", fontSize: "12px" },
    
      },
      {
        label: "Action",
        name: "Action",
        render: ({ Action }) =>
          <Flex>
   
               <Icon 
               name="Eye"
               w="20"/>
           
          </Flex>
      
      },
      {
        label: "",
        name: "delete",
        render: ({ id }) =>
          <Flex 
          sx={{
          w:"35px",
          h:"35px",
          br:"50%",
          }}
          style={{
          backgroundColor:"#f7eaea"
          }}
          onClick={()=>DeleteUser(id)}>
          {!confirm[id] ? (
          <Icon name="trash2" w="16" c="red" />
        ) : (
          <Loading size={18} />
        )}
         
          </Flex>
      
      },
    ];
    
    return (
        <Layout admin>
            <TableContainer 
            data={list}  
            cols={columns}
            checkbox
            mixedHeader
            />
        </Layout>
    );
}
export default Users