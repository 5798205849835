import Arrow1 from "../../HomePageSvg/Arrow1";
// import img6 from "assets/contactImg.svg";

function FourthImage({setClick}){
    return(
    <div className="header-img-container">
        <img
        className="content-img1 clickable"
        onClick={(e) => setClick(5)}
        src={"https://forms-easy.com/assets/contactImg.svg"}
        alt="fill form"
        />
        <div className="div1">
          <div className="click-form">Click to fill form ✍️</div>
          <Arrow1/>
        </div>
    </div>
    )
}
export default FourthImage;