import SelectedBox from "./SelectedBox";

function Selected({
  label,
  refEl,
  styles,
  selected,
  query,
  handleInput,
  defaultValue,
  disabled,
  search,
  ...rest
}) {
  // function getSelected() {
  //   if(!(selected.length && selected[0].hasOwnProperty("data"))) return '';

  //   return selected;
  // }

  return (
    <SelectedBox ref={refEl} {...rest} $isValStr={selected?.length && typeof selected[0].name === 'string'} className="selected">
      {selected.map((s, i) => (
        <span key={i} className="item">{s.name}</span>
      ))}
    </SelectedBox>
  );
}

export default Selected;
