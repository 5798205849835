import React, { useState } from "react";
import styled, { css } from "styled-components";
import Input from "./Input";
import Label from "./Label";
import Type from "./Type";
import Icon from "./Icon";
import stylesBlocks from "./core/stylesBuilder";

const Wrapper = styled.div`
  margin-bottom: 10px;
  ${(p) =>
    p.space &&
    css`
      margin-bottom: ${(p) => p.space};
    `}

  .inputWrap {
    position: relative;
    &.icon-left {
      input {
        padding-left: 45px;
      }
    }
    &.icon-right {
      input {
        padding-right: 40px;
      }
    }
    > span {
      position: absolute;
      height: 100%;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
      top: 50%;
      transform: translate(0, -50%);
      pointer-events: none;
      &.clickable {
        pointer-events: auto;
        cursor: pointer;
      }
      svg {
        width: 16px;
        fill: ${(p) => p.theme.color.gray700};
      }
    }
  }



  .dragArea {
    outline: ${(props) => props.theme.color.gray300} 1px solid;
    border: 2px dashed ${(p) => p.theme.color.gray400};
    border-radius: 4px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    padding: 20px;
    cursor: pointer;
    background-color: ${(p) =>
      p.$isDragging ? p.theme.color.gray100 : "transparent"};
    height: 34px;
    transition: background-color 0.3s;
    font-size: ${(p) => (p.$size === "small" ? "14px" : "16px")};
    input[type="file"] {
      display: none;
    }
    .dragText {
      color: ${(p) => p.theme.color.gray500};
    }
    label {
      color: ${(p) => p.theme.color.primary};
      text-decoration: underline;
      cursor: pointer;
      font-size: ${(p) => (p.$size === "small" ? "14px" : "16px")};
    }
  }

  .fileDisplay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: ${(p) => p.theme.color.gray100};
    padding: 0 15px;
    border-radius: 6px;
    height: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
    &:hover {
      background-color: ${(p) => p.theme.color.gray200};
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .fileName {
      font-size: ${(p) => (p.$size === "small" ? "14px" : "16px")};
      color: ${(p) => p.theme.color.gray700};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

  }

  ${stylesBlocks}
`;

function InputText({
  prefix,
  label,
  value,
  name,
  id,
  onChange,
  placeholder = "",
  type,
  msg,
  error = false,
  iconLeft,
  iconRight,
  size = "large",
  inputProps,
  onFileSelect,
  accept = "*",
  multiple = false,
  enableFileUpload = false,
  defaultFile,
  ...props
}) {
 

  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(
    defaultFile ? { name: defaultFile.split("/").pop(), url: defaultFile } : null
  );

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      onFileSelect && onFileSelect(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      onFileSelect && onFileSelect(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
    onFileSelect && onFileSelect(null);
  };

  return (
    <Wrapper
      {...props}
      $size={size}
      $isDragging={isDragging}
      className={`${error && "error"} ${(iconLeft || iconRight) && "icon"}`}
    >
      {label && <Label htmlFor={id || inputProps?.id}>{label}</Label>}
      {enableFileUpload ? (
        selectedFile ? (
          <div className="fileDisplay">
            {selectedFile.url ? (
              <>
                <a
                  href={selectedFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fileName"
                >
                  {selectedFile.name}
                </a>
                <Icon name="Xsign" onClick={handleDeleteFile} />
              </>
            ) : (
              <>
                <span className="fileName">{selectedFile.name}</span>
                <Icon name="Xsign" onClick={handleDeleteFile} />
              </>
            )}
          </div>
        ) : (
          <div
            className="dragArea"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <span className="dragText">Drag and drop a file here or</span>
            <label htmlFor={`${id}-file`}>click to browse</label>
            <input
              id={`${id}-file`}
              type="file"
              accept={accept}
              onChange={handleFileChange}
            />
          </div>
        )
      ) : (
        <div
          className={`inputWrap ${iconLeft && "icon-left"} ${
            iconRight && "icon-right"
          }`}
        >
          {iconLeft && (
            <span
              className={`left ${
                typeof iconLeft !== "string" && "clickable"
              }`}
              onClick={
                typeof iconLeft === "string" ? undefined : iconLeft[1]
              }
            >
              <Icon
                name={typeof iconLeft === "string" ? iconLeft : iconLeft[0]}
              />
            </span>
          )}
          {iconRight && (
            <span
              className={`right ${
                typeof iconRight !== "string" && "clickable"
              }`}
              onClick={
                typeof iconRight === "string" ? undefined : iconRight[1]
              }
            >
              <Icon
                name={typeof iconRight === "string" ? iconRight : iconRight[0]}
              />
            </span>
          )}
          {prefix && <span className="prefix">{prefix}</span>}
          <Input
            as={type === "textarea" ? "textarea" : "input"}
            id={id || inputProps?.id}
            aria-label={id || inputProps?.id || label || placeholder}
            {...{
              prefix,
              value,
              type,
              name,
              onChange,
              placeholder,
              $size: size,
              ...inputProps,
            }}
          />
        </div>
      )}
      {msg && (
        <Type sx={{ c: error ? "red" : "gray600", mt: 2, fs: "12px" }}>
          {msg}
        </Type>
      )}
    </Wrapper>
  );
}

export default InputText;
