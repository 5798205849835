import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, Checkbox, Flex, InputText, Type } from "ui";
import { useAuth } from "services/auth";
import { useNavigate } from "react-router-dom";
import { useProfile } from "views/Account/provider";

const schema = Yup.object().shape({
  email: Yup.string().required("Required.").email("Invalid email."),
  password: Yup.string()
    .required("Required.")
    .min(4, "Too Short! at least 4 letters is required!"),
});

function LoginForm({ onSuccess }) {
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  let navigate = useNavigate();
  const auth = useAuth();
  const header = useProfile();
  let emailName = header.em;
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmit = async (values) => {
    setError(null);
    try {
      await auth.signin(values,rememberMe);
      reset();
      onSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      console.log("error", e);
      if (e.status === 401) setError(true);
    }
  };

  return (
    <Box
      as="form"
      sx={{ w: [370,"_","_","90%"], br: 10 }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Box
        sx={{
          d: error ? "block" : "none",
          p: 8,
          bgc: "redBg",
          w: "100%",
          border: ["red", 10, "solid", 5, "r"],
          mb: 10,
          ta: 'left'
        }}
      >
        That combination of username and password did not work, please try
        again.
      </Box>
      <InputText
        label="E-mail address"
        error={!!errors.email}
        msg={errors.email?.message}
        inputProps={{
          ...register("email"),
          // autoFocus: true,
          defaultValue: emailName,
          autoComplete:"email"
        }}
      />
      <InputText
        label="Password"
        type={showPass ? "text" : "password"}
        iconRight={[
          showPass ? "eyeSlash" : "eye",
          () => setShowPass((showPass) => !showPass),
        ]}
        error={!!errors.password}
        msg={errors.password?.message}
        inputProps={{
          ...register("password"),
          required: true,
          autoComplete:"password"
        }}
      />
      <Box sx={{ w: [370,"_","_","100%"], br: 10 }}>
        <Flex
        jc="space-between"
        >
          <Checkbox 
          size="small" 
          label="Remember me" 
          id="remember" 
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
          />
          <Type
            id="1"
            style={{ marginLeft: "auto", cursor: "pointer"}}
            var={"h6"}
            sx={{c:"darkBlue"}}
            onClick={() => navigate("/forgot-password")}
          >
            Forget your Password?
          </Type>
        </Flex>
      </Box>
      <Button
        type="submit"
        loading={isSubmitting}
        sx={{ mx: "auto", w: "100%" }}
      >
        Login
      </Button>
      <Button
        variant="outlined"
        onClick={() => navigate("/create-account")}
        sx={{ w: "100%", mt: 20 }}
      >
        Create a new account
      </Button>
    </Box>
  );
}

export default LoginForm;
