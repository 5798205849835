import { yupResolver } from "@hookform/resolvers/yup";
import LayoutMasked from "layout/LayoutMasked";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "services/auth";
import { useUtils } from "services/utilsProvider";
import { Box, Button, InputText, Link, Type } from "ui";
import * as Yup from "yup";

export default function ResetPassword() {
  const [error, setError] = useState();
  const [searchParams, _] = useSearchParams();
  const { notify } = useUtils();
  const navigate = useNavigate();

  const { resetPass } = useAuth();

  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .required("required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPass: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match"
    ),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    setError(null);
    try {
      await resetPass(values.newPassword, searchParams.get("token"));
      reset();
      notify("Password has been changed.","success");
      navigate("/login");
    } catch (e) {
      console.log("error", e);
      setError(e.data.message);
    }
  };

  return (
    <LayoutMasked title="Create a new password">
      <Box
        sx={{ w: [420, 420, 420, "100%"], px: 30, pt: 60 }}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Type sx={{ mb: 20, c: "red" }}>{error && error}</Type>

        <InputText
          label="New Password"
          placeholder="newPassword"
          type="password"
          error={!!errors.password}
          msg={errors.password?.message}
          inputProps={{
            ...register("newPassword"),
          }}
        />
        <InputText
          label="Confirm Password"
          placeholder="Confirm Password"
          type="password"
          error={!!errors.confirmPass}
          msg={errors.confirmPass?.message}
          inputProps={{
            ...register("confirmPass"),
          }}
        />
        <Button
          size="large"
          type="submit"
          loading={isSubmitting}
          sx={{ mt: 30, w: "100%" }}
        >
          Reset Password
        </Button>
        <Box sx={{ mt: 30, ta: "center", fw: 500}}>
          <Link to="/forgot-password">Back</Link>
        </Box>
      </Box>
    </LayoutMasked>
  );
}
