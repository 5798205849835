import React, { useState } from "react";
import { Box, Button, Flex, Icon, InputText, Type } from "ui";
import { useProfile } from "./provider";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import Dialog from "ui/Dialog";
import "./profile.css"
import { useUtils } from "services/utilsProvider";
import { useAuth } from "services/auth";
import { useNavigate } from "react-router-dom";

const schema = Yup.object().shape({
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
});

function Profile() {
  const { signout } = useAuth();
  const navigate = useNavigate();
  const { notify } = useUtils();
  const [showDelete, setShowDelete] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { 
    state:{selected},
    update,
    remove,
  } = useProfile();

  const onSubmit = async (values) => {
    try {
      await update(selected.id, values);
      notify('Account Info Updated !','success')
    } catch (e) {
      window.scrollTo(0, 0);
      console.log("error", e);
    }
  };
  const handleDelete = () => {
    setShowDelete(true);
  };
  const handleDeleted = async () => {
    try {
      console.log(selected.id,"profileID")
      await remove(selected.id);
      notify("Profile has been removed successfully.", "success");
      setShowDelete(false);
      signout();
      navigate("/");
    } catch (error) {
      console.log(error);
      notify(error.errors.message, "alert");
    }
  }
  return (
    <>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <InputText
          label="Name"
          name="firstName"
          size="large"
          data-testid="firstName"
          error={!!errors["firstName"]}
          msg={errors["firstName"]?.message}
          inputProps={{
            ...register("firstName"),
            "aria-label": "firstName",
            defaultValue : selected?.firstName
          }}
        />

        <InputText
          className="profile-input"
          label="Surname"
          name="lastName"
          data-testid="lastName"
          error={!!errors["lastName"]}
          msg={errors["lastName"]?.message}
          inputProps={{
            ...register("lastName"),
            "aria-label": "lastName",
            defaultValue : selected?.lastName
          }}
        />

        <InputText
          label="Email"
          name="primaryVerifiedEmail"
          msg={'this field is disabled , you can not change your Verified Email '}
          inputProps={{
            disabled: true,
            defaultValue: selected && selected["primaryVerifiedEmail"],
          }}
        />
        <Button
          type="submit"
          loading={isSubmitting}
          size="large"
          style={{ color: "white", backgroundColor: "#061492" }}
          sx={{ mt: 20, w: "100%" }}
        >
          Save Changes
        </Button>
      
      </Box>
      <Button variant="outlined" size="large" bg="red" sx={{ mt: 40, w: "100%",mb:20 }}  onClick={() =>handleDelete()}>
          Delete My Account
      </Button>
      <Dialog  show={showDelete} setShow={setShowDelete} onClose={() => setShowDelete(0)}>
      <Box sx={{ p: 25, mxw: [600, 600, 600, "calc(100vw - 40px)"] }}>
        <Type sx={{ mb: 25 }} var="h4">
          Delete Account
        </Type>
        <Type sx={{ mt: 20, mb: 20 }}>
          this action can not be undone.
        </Type>
      </Box>
      <Box sx={{ bgc: "primaryBg", px: 25, py: 15 }}>
        <Flex jc="flex-end">
          <Button
            size="small"
            variant="outlined"
            type="button"
            onClick={() => setShowDelete(0)}
            sx={{ mr: 10 }}
            bg="black"
          >
            No thanks
          </Button>
          <Button
            size="small"
            type="button"
            onClick={handleDeleted}
          >
            Delete my account
          </Button>
        </Flex>
      </Box>
    </Dialog>
    </>
  );
}

export default Profile;
