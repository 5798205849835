import { formatDate } from "date-fns";
import { Badge, Box, Button, Card, Flex, Icon, Type } from "ui";
import { useSubmissionForm } from "../provider";
import { useNavigate } from "react-router-dom";
import { useSubmission } from "views/submissions/provider";
import { useEffect, useState } from "react";
import { useUtils } from "services/utilsProvider";

const SubmissionStat = ({ label, value }) => {
  return (
    <Box sx={{ ta: "center" }}>
      <Type sx={{ fs: 30, fw: 600, mb: 2, c: "gray500" }}>{value}</Type>
      <Type sx={{ fs: 12, c: "gray500" }}>{label}</Type>
    </Box>
  );
};

export default function FormCard({
  form,
  setOpenDeleteDialog,
  projectId,
  ...restProps
}) {
  const [totals, setTotals] = useState();
  const navigate = useNavigate();
  const { setSelectedForm } = useSubmissionForm();
  const { fetchTotals } = useSubmission();
  const { notify } = useUtils();

  useEffect(() => {
    if (!projectId || !form.id) return;
    (async () => {
      setTotals(await fetchTotals({ formId: form.id, projectId }));
    })();
  }, [fetchTotals, projectId, form]);

  const handleShow = () => {
    setSelectedForm(form);
    navigate(`/project/${projectId}/form/${form.id}`);
  };

  const copyId = () => {
    navigator.clipboard.writeText(form.uniqueId);
    notify("Copied the text: " + form.uniqueId, "success");
  };

  const handleDelete = () => {
    setOpenDeleteDialog(form);
  };

  if (!form || !totals) return;

  return (
    <Card
      relative
      shadow={true}
      key={form.id}
      sx={{ w: "100%", mb: 20, mt: 8 }}
      {...restProps}
    >
      <Box abs={{ t: 25, r: 20 }} sx={{ cursor: "pointer", mt: -8 }}>
        <Icon onClick={handleDelete} name="Trash" w={14} c="red" hover />
      </Box>

      <Flex
        dir="column"
        gap={5}
        ai="flex-start"
        style={{
          paddingBottom: 14,
          borderBottom: "1px solid red",
          borderImage:
            " linear-gradient(to right, transparent, #E0E5F2, transparent) 1",
        }}
      >
        <Flex gap={7}>
          <Type var="h6" sx={{ fw: 700 }}>
            {form.name}
          </Type>
          <Badge onClick={copyId}>ID {form.uniqueId}</Badge>
        </Flex>
        <Type sx={{ c: "gray500", fs: 13 }}>
          Created at {formatDate(new Date(form.createDate), "dd, MMM, yyyy")}
        </Type>
      </Flex>

      <Type var="h5" sx={{ mt: 14 }}>
        Submissions
      </Type>

      <Flex gap={25} sx={{ my: 20 }}>
        <SubmissionStat value={totals.monthlyRate} label="Monthly" />
        <SubmissionStat value={totals.lastMonthCount} label="Last Month" />
        <SubmissionStat value={totals.allTimeCount} label="All Time" />
      </Flex>

      <Button onClick={handleShow} sx={{ w: "100%" }}>
        View details
      </Button>
    </Card>
  );
}
