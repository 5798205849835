import formal from "assets/formal.svg";
import fancy from "assets/fancy.svg";
import custom from "assets/custom.svg";
import {
  Button,
  Flex,
  InputRadio,
  InputText,
  Link,
  Select,
  Type,
} from "ui";
import CustomEmail from "./CustemEmail";
import { ExpandedContent } from "./Components";
import { useEffect, useState } from "react";
import { AlignedButton, FlexStyled, SecondRowItem, StyledBox } from "Components";

function AutoReplay({ 
    notify,
    editARS,
    FetchcustomTemplate,
    createARS,
    selectedForm,
    ARS,
    logoURL,
    globalTemplates,
    customTemplate,
    FetchglobalTemplate,
    editcustomTemplate,
    selectedGlobalTheme,
    UploadFile,
    uploadImageToS3
})  {
    const [error, setError] = useState([]);
    const [autoReply, setAutoReply] = useState(Object.keys(ARS).length === 0?false:ARS?.isEnabled);
    const [ customEmail ,setCustomEmail ] = useState (false);

    useEffect(()=>{
        setAutoReply(Object.keys(ARS).length === 0?false:ARS?.isEnabled)
      },[setAutoReply,ARS])

    const CreateOrEditARS =async(e) => {  
      if (Object.keys(ARS).length === 0){
        if(autoReply===true){
          const data = {
            autoReplyField: " ",
          };
          try {
            await createARS(data,selectedForm?.id);
            await FetchcustomTemplate(selectedForm?.id);
            notify(' auto replay emails added successfully','success')
            setError([])
          } catch (e) {
            setError(e.data.message)
          }
        }else{
          setError(['you must toggle the button to add auto replay emails to respondents '])
        }
      }else{
        const data = {
          isEnabled:autoReply,
        };
        try {
          await editARS(data,selectedForm?.id);
          notify(' edited successfully','success')
          setError([])
        } catch (e) {
          setError(e.data.message)
        }
      }
    };
  return (
    <>
        <FlexStyled style={{ justifyContent: "flex-start" }}>
        <InputRadio
          toggleButton={true}
          checked={autoReply}
          inputProps={{
            checked: autoReply,
            onChange: () => setAutoReply(!autoReply),
          }}
        />
        <StyledBox>
          <Type>Auto reply</Type>
          <Type variant="subtitle">
            Send a customized email to respondents after successful form
            submission
          </Type>
        </StyledBox>
      </FlexStyled>
      <ExpandedContent className={ARS?.isEnabled===true?"active":""}>
        <FlexStyled>
          <span />
          <StyledBox>
            <Type>Email notification template</Type>
            <Type variant="subtitle">Customize email template</Type>
          </StyledBox>
          <SecondRowItem>
            <Flex dir="column" sx={{ai:["flex-end","_","_","flex-start"]}} gap={10}>
              <Type>Selected Theme</Type>
              <Flex
                dir="column"
                gap="2px"
                sx={{
                  br: 10,
                  px: 20,
                  py :10,
                  bgc: "babyBlue",
                }}
              >
                <img src={ ARS?.activeTemplateName ==='Formal'? formal :ARS?.activeTemplateName ==='Fancy'? fancy :custom} alt=""/>
                <Type>{ARS?.activeTemplateType==='global'? ARS?.activeTemplateName : ARS?.activeTemplateType}</Type>
              </Flex>
              <Button 
              size="small" 
              iconName="Customize"
              onClick={()=>{setCustomEmail(!customEmail)}} >
                Customize
              </Button>
            </Flex>
          </SecondRowItem>
        </FlexStyled> 
       
      </ExpandedContent>
      <AlignedButton 
        onClick={(e) => CreateOrEditARS(e)}
        >
          {Object.keys(ARS).length !== 0 ? "save" : "add"}
      </AlignedButton>
      <Flex
        role="alert"
        sx={{
          d: error.length ? "block" : "none",
          p: 5,
          bgc: "redBg",
          w: "100%",
          border: ["red", 10, "solid", 5, "r"],
          my: 10,
        }}
      >
        {error.map((err,index) => (
          <Type key={index}>{err}</Type>
        ))}
      </Flex>
      {customEmail ? (
      <CustomEmail {...{
        customEmail,
        selectedForm,
        setCustomEmail,
        ARS,
        globalTemplates,
        customTemplate,
        FetchglobalTemplate,
        FetchcustomTemplate,
        editcustomTemplate,
        selectedGlobalTheme,
        editARS,
        notify,
        UploadFile,
        uploadImageToS3,
        logoURL
      }}/>
      ):(
        <>
        </>
    )
    }
    </>
  );
}
export default AutoReplay;
