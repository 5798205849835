import { Box, Button, Flex, Type } from "ui";
import Dialog from "ui/Dialog";
import { useSubmissionForm } from "../provider";
import { useUtils } from "services/utilsProvider";

export default function DeleteFormDialog({
  openDeleteDialog,
  setOpenDeleteDialog,
  projectId,
}) {
  const { deleteForm } = useSubmissionForm();
  const { notify } = useUtils();

  const handleDelete = async () => {
    try{
      await deleteForm(projectId, openDeleteDialog.id);
      setOpenDeleteDialog(false);
      notify(`Form deleted successfully`,"success");
    }catch(error) {
      console.log("console error", error);
    }   
  };

  return (
    <Dialog
      show={openDeleteDialog&&true}
      onClose={() => setOpenDeleteDialog(false)}
    >
      <Box sx={{p: 20}}>
        <Type
          var="h5"
          sx={{
            c: "type",
            fw: 700,
            lh: 30,
          }}
        >
          Delete {openDeleteDialog.name} ?
        </Type>
        <Type var="subtitle" sx={{ c: "type", fs: "14px", ff: "secondary" }}>
          Are you sure about that? this can't be undone!
        </Type>
      </Box>

      <Flex
        pos={{ b: 0, r: 0 }}
        dir="row"
        gap={10}
        jc="flex-end"
        sx={{
          p: 10,
          w: "100%",
          bgc: "babyBlue",
          br: "0 0 4px 4px",
        }}
      >
        <Button variant="outlined" onClick={() => setOpenDeleteDialog(false)}>
          Cancel
        </Button>
        <Button onClick={handleDelete}>Approve</Button>
      </Flex>
    </Dialog>
  );
}
