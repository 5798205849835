import React, { useEffect, useState } from "react";

import { Box } from "ui";
import { ExpandedContent } from "./Components";
import GeneralSettings from "./GeneralSettings";
import SpamProtection from "./SpamProtection";
import Security from "./Security";
import Recaptcha from "./Recaptcha";
import Hcaptcha from "./Hcaptcha";
import Processing from "./Processing";
import DangerZone from "./DangerZone";
import Heading from "./Heading";
import { useSubmissionForm } from "../provider";
import { useUtils } from "services/utilsProvider";
import { useParams } from "react-router-dom";
import { useProject } from "views/project/provider";
import { useProfile } from "views/Account/provider";
import AutoReplay from "./AutoReplay";

const data = [
  {
    index: 1,
    iconName: "Gear",
    title: "General Settings",
    subTitle: "Data retention, limit number of responses, etc.",
    Content: GeneralSettings,
  },
  {
    index: 2,
    iconName: "WarningOctagon",
    title: "Spam Protection",
    subTitle: "Data retention, limit number of responses, etc.",
    Content: SpamProtection,
  },
  {
    index: 3,
    iconName: "Security",
    title: "Security",
    subTitle:
      "Only accept submissions from whitelisted domains. Will help prevent spam",
    Content: Security,
  },
  {
    index: 4,
    iconName: "Security",
    title: "reCAPTCHA",
    subTitle:
      "reCAPTCHA provides spam protection. Disabling it will remove the reCAPTCHA redirect.",
    Content: Recaptcha,
  },
  // {
  //   index: 5,
  //   iconName: "Security",
  //   title: "hCaptcha",
  //   subTitle: " Paste your hCaptcha Secret Key to protect your form",
  //   Content: Hcaptcha,
  // },
  {
    index: 5,
    iconName: "Gear",
    title: "Notifications",
    subTitle: "",
    Content: Processing,
  },
  {
    index: 6,
    iconName: "Gear",
    title: "Auto Replay",
    subTitle: "",
    Content: AutoReplay,
  },
  {
    index: 7,
    iconName: "trash2",
    title: "Danger Zone",
    subTitle: "Terminate your form with all of its data",
    iconColor: "darkBlue",
    Content: DangerZone,
  },
];

function FormSettings() {
  const [expandedRows, setExpandedRows] = useState([]);
  const {
    state: { selected :SelectedForm , ARS , globalTemplates ,customTemplate,selectedGlobalTheme,logoURL},
    EditForm,
    fetchARS,
    createARS,
    editARS,
    FetchglobalTemplates,
    FetchcustomTemplate,
    editcustomTemplate,
    FetchglobalTemplate,
    UploadFile,
    uploadImageToS3
  } = useSubmissionForm();
  const {state:{selected:selectedUser},fetchProfile} = useProfile();
  const { projectId } = useParams();
  const {
    state: { projectDetails },
    fetchProjectDetails,
  } = useProject();

  useEffect(() => {
    const getProjectDetails =async(e) => {  
      try {
        await fetchProjectDetails(projectId);
      } catch (e) {
        console.log(e);
      }
    };
    getProjectDetails();
  }, [fetchProjectDetails, projectId]);

  const { notify } = useUtils();

  const toggleExpansion = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const edit = async (data) => {
    try {
      await EditForm(data, projectId, SelectedForm?.items?.id);
      notify(`form settings edited`, "success");
    } catch (e) {
      throw e;
    }

  };
  useEffect(()=>{ 
    const getARS =async(e) => {  
      try {
        await fetchARS(SelectedForm?.items?.id);
      } catch (e) {
        console.log(e);
      }
    };
    const getglobalTemplates =async(e) => {  
      try {
        await FetchglobalTemplates();
      } catch (e) {
        console.log(e);
      }
    };
    const getProfile =async(e) => {  
      try {
        await fetchProfile();
      } catch (e) {
        console.log(e);
      }
    };
    const getcustomTemplate =async(e) => {  
      try {
      await FetchcustomTemplate(SelectedForm?.items?.id);
      } catch (e) {
      console.log(e);
      }
    };
    getcustomTemplate()
    getARS();
    getglobalTemplates()
    getProfile()
    },[fetchARS,SelectedForm,FetchglobalTemplates,fetchProfile,FetchcustomTemplate])
 
    
  return (
    <Box sx={{ mb: "100px" }}>
      {data.map(({ Content, ...item }) => (
        <React.Fragment key={item.index}>
          <Heading
            toggleExpansion={toggleExpansion}
            index={item.index}
            iconName={item.iconName}
            title={item.title}
            subtitle={item.subTitle}
            iconColor={item.iconColor}
          />

          <ExpandedContent
            className={expandedRows.includes(item.index) ? "active" : ""}
          >
            <Content
              edit={edit}
              selectedForm={SelectedForm?.items}
              projectDetails={projectDetails}
              notify={notify}
              ARS={ARS}
              createARS={createARS}
              editARS={editARS}
              globalTemplates={globalTemplates}
              customTemplate={customTemplate}
              FetchglobalTemplate={FetchglobalTemplate}
              FetchcustomTemplate={FetchcustomTemplate}
              selectedGlobalTheme={selectedGlobalTheme}
              selectedUser={selectedUser}
              UploadFile={UploadFile}
              uploadImageToS3={uploadImageToS3}
              editcustomTemplate={editcustomTemplate}
              logoURL={logoURL}
            />
          </ExpandedContent>
        </React.Fragment>
      ))}
    </Box>
  );
}
export default FormSettings;
