import { useCallback, useEffect, useState } from "react";
import {  Button, Flex, InputRadio, InputText, Type } from "ui"
import { useIntegrations } from "./provider";
import { useParams } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useUtils } from "services/utilsProvider";

function IntegrationCard ({img,title,description,id,data,isEnabled}) {
    const [toggle,setToggle] =useState(false)
    const [webHookUrl, setWebHookUrl] = useState();
    const [discordError, setDiscordError] = useState();
    const [GoogleError, setGoogleError] = useState(null);
    const [googleCode,setGoogleCode] =useState(null);
    const [enable, setEnable] = useState(isEnabled);
    const botUsername = 'forms_easy_bot'; // Replace with your bot's username (without @)  
    const { formId, projectId } = useParams();
    const [googleSheetTitle,setGoogleSheetTitle] =useState("");
    const { notify } = useUtils();
    const {
        createTelegramIntegration,
        editDiscordIntegration,
        deleteIntegration,
        createDiscordIntegration,
        createGoogleSheetIntegration,
        editGoogleSheetIntegration,
        editTelegramIntegration
      } = useIntegrations();
////////////////////////////////telegram//////////////////////////////////////
    // Utility function to delete a specific cookie    
    // const deleteCookie = (name) => {
    //     document.cookie = name + '=; Max-Age=-99999999; path=/;';
    // };
    const onTelegramAuth = useCallback( async (user) => {  
        // Handle user authentication here  
        // console.log('User authenticated:', user);  
        try {
            await createTelegramIntegration(user,projectId,formId)
            // Hide the Telegram login button
            // const telegramButtonContainer = document.getElementById('telegram-button-container');
            // if (telegramButtonContainer) {    
            //     telegramButtonContainer.style.display = 'none'; // Hide the container
            // }

            // Clear Telegram-specific cookies
            // deleteCookie('stel_ssid');
            // deleteCookie('stel_token');

            setToggle(false);
            notify('integration with telegram is done !',"success");
        } catch (error) {
            console.log(console.error);  
        }
      },
      [createTelegramIntegration,formId,projectId,notify]
    );

    useEffect(() => {  
        const telegramButtonContainer = document.getElementById('telegram-button-container');
        if (telegramButtonContainer) {
        window.onTelegramAuth = onTelegramAuth;
        const script = document.createElement('script');  
        script.src = "https://telegram.org/js/telegram-widget.js?22";
        script.async = true;  
        script.setAttribute('data-telegram-login', botUsername);  
        script.setAttribute('data-size', 'large');  
        script.setAttribute('data-onauth', `onTelegramAuth(user)`);  
        script.setAttribute('data-request-access', 'write');  
        // script.setAttribute('data-auth-url', 'https://a292-18-168-210-20.ngrok-free.app '); 
        telegramButtonContainer.appendChild(script);  

        return () => {  
            telegramButtonContainer.removeChild(script);
        }
        }  
      }, [botUsername,onTelegramAuth]); 

    const editTIntegration = async(isEnabled,id) => {
    try {
        const data ={
            isEnabled:enable,
        }
        await editTelegramIntegration (data,projectId,formId,id)
        setToggle(false)
        notify('integration edited successfully !',"success");
    } catch (error) {
        console.log(error);
    }
};

/////////////////////////////////discord//////////////////////////////////////
    const handleWebHookUrlChange = useCallback((event) => {
        setWebHookUrl(event.target.value);
      }, []);

    const discordIntegration = async() => {
        try {
            const data ={
                webHookUrl :webHookUrl
            }
            await createDiscordIntegration (data,projectId,formId)
            setDiscordError("")
            setToggle(false)
            notify('integration with discord is done !',"success");
        } catch (error) {
            console.log(error);
            setDiscordError(error.data.message)
        }
    
        
    };
    const editDIntegration = async(isEnabled,id) => {
        try {
            const data ={
                isEnabled:enable,
                webHookUrl :webHookUrl,
            }
            await editDiscordIntegration (data,projectId,formId,id)
            setDiscordError("")
            setToggle(false)
            notify('integration edited successfully !',"success");
        } catch (error) {
            console.log(error);
            setDiscordError(error.data.message)
        }
    
        
    };
    const DeleteIntegration = async(id) => {
        try {
            await deleteIntegration (projectId,formId,id)
            setDiscordError("");
            setToggle(false);
            notify('integration deleted successfully !',"success");
        } catch (error) {
            console.log(error);
            setDiscordError(error.data.message)
        }
    
        
    };

    // Sync enable with isEnabled when the component mounts or when isEnabled changes
    useEffect(() => {
        setEnable(isEnabled);
    }, [isEnabled]);
  
    const handleToggleChange = () => {
        setEnable(prev => !prev);
    };
/////////////////////////////////google sheets//////////////////////////////////////

    const loginWithGoogle = useGoogleLogin({
        scope: 'https://www.googleapis.com/auth/drive.file',
        hint: 'Login',
        ux_mode: 'popup',
        flow: 'auth-code',
        overrideScope: true,
        onSuccess: (res) => {
            try {
                setGoogleCode(res.code)
            } catch (error) {
                console.log(console.error);  
            }
        },
        onError: (error) => console.log("Login Failed:", error),
    });
    const handleGoogleSheetTitleChange = useCallback((event) => {
        setGoogleSheetTitle(event.target.value);
      }, []);

    const GoogleSheetIntegration = async() => {
    try {
        const data ={
            code :googleCode,
        }
        if (googleSheetTitle !==""){
            data.title =googleSheetTitle
        }
        await createGoogleSheetIntegration (data,projectId,formId);
        setGoogleError("");
        setGoogleCode(null);
        setToggle(false);
        notify('integration with google sheets is done !',"success");
    } catch (error) {
        console.log(error);
        setGoogleError(error.data.message)
    }
    };
    const editGIntegration = async(isEnabled,id) => {
        try {
            const data ={
                isEnabled:enable,
            }
            await editGoogleSheetIntegration (data,projectId,formId,id);
            setToggle(false);
            notify('integration edited successfully !',"success");
        } catch (error) {
            console.log(error);
        }
    
        
    };

return (
    <Flex 
    dir="column"
    pos="relative"
    ai="flex-start"
    jc="space-betwwen"
    gap="5px"
    sx={{  
        p:["30px 10px","_","10px","_"] ,                                                                                                                                          
        w:["80%","_","100%","_"],
        shadow:"4px 4px 6px rgba(0, 0, 0, 0.2)",
        h:toggle===true?["280px","_","350px","_"]:["100px","_","200px","_"],
        tr: 'height 0.5s ease', 
    }}
    
   >
    <Flex 
    gap="10px"
    dir={["row","_","column","_"]}
    jc="space-between"
    sx={{
        w:"100%"
    }}>
        <Flex
        gap="10px"
        jc="flex-start"
        ai="flex-start"
        sx={{
            w:["80%","_","100%","_"],
        }}>
        <Flex>
            <img src={img} alt={title}/>
        </Flex>
        <Flex
        dir="column"
        ai="flex-start"
        sx={{
            w:"100%",
            h:"auto"
        }}>
            <Type 
            var="h6"
            sx={{
                fw:700
            }}
            >
                {title}
            </Type>
            <Type 
            sx={{
                fs:"12px",
                fw:"600",
                c:"gray600"
            }}
            >
                {description}
            </Type>
        </Flex>
        </Flex>
        <Flex 
        sx={{
            w:["fit-content","_","100%","_"]  ,
        }}
        gap="5px"
       >
    
            <Button
            disabled={id===null?false:true}
            size="small"
            sx={{
                grow:"1",
                minw:"fit-content"
            }}
              onClick = {()=>setToggle(!toggle)}
           >
                Add info
            </Button>
            
            <Button
            disabled={id===null?true:false}
            size="small"
            variant = "outlined"
            sx={{
                 grow:"1"
            }}
            onClick = {()=>setToggle(!toggle)}
            >
                edit
            </Button>

            <Button
            disabled={id===null?true:false}
            size="small"
            variant = "outlined"
            bg="red"
            sx={{
                 grow:"1"
            }}
            onClick = {()=>DeleteIntegration(id)}
            >
                Delete
            </Button>   
        </Flex>
        </Flex>
     

    {title==="discord"?
    <Flex 
    dir="column"
    jc="flex-start"
    gap="10px"
    ai="flex-end"
    style={{
        visibility:toggle?"visible":"hidden",
        width:"100%",
        padding:"10px"
    }}>
        {id!==null&&
        <InputRadio
        style={{
            alignSelf:"flex-start"
        }}
        label={"enable or disable discord integration"}
        toggleButton={true}
        checked={enable}
        inputProps={{
            checked: enable,
            onChange: handleToggleChange,  // Call toggle handler when the user interacts with the toggle
        }}
        />}
        <InputText
        style={{                                                                                                                                                                                                                                           
            width:"100%"
        }}
        label="Enter your webHookUrl"
        name="webHookUrl"
        size="small"
        data-testid="webHookUrl"
        placeholder={data?.webHookUrl?data.webHookUrl:"Enter your webHookUrl"}
        onChange={handleWebHookUrlChange}
        error={discordError}
        msg={discordError}
        inputProps={{
          defaultValue : data?.webHookUrl
        }}
        />
        <Button 
        size="small"
        onClick={() => {
            if (id === null) {
              discordIntegration();  // Call the first function when id is null
            } else {
                editDIntegration(isEnabled,id);  // Call another function when id is not null
            }
          }}
        >
            {id===null?"connect":"edit"}
        </Button>
    </Flex>


    :title==="telegram"?
    id===null?
    <div style={{visibility:toggle===true?"visible":"hidden",alignSelf:"center",marginTop:"20px"}} id="telegram-button-container" ></div> 
    :
   
    <Flex
    dir = "column"
    jc="center"
    ai="flex-end"
    style={{
        visibility:toggle?"visible":"hidden",
        padding:"10px"
    }}
    sx={{
        w:"100%",
    }}>
    <InputRadio
    style={{
        alignSelf:"flex-start",
    }}
    label={"enable or disable telegram integration"}
    toggleButton={true}
    checked={enable}
    inputProps={{
        checked: enable,
        onChange: handleToggleChange,  // Call toggle handler when the user interacts with the toggle
    }}
    />
    <Button 
    size="small"
    onClick={() => {
          editTIntegration(isEnabled,id );  
      }}
    >
        edit
    </Button>
    </Flex>


    
    :title==="google-sheets"?
    <Flex
    dir = "column"
    jc="center"
    ai="flex-end"
    style={{
        visibility:toggle?"visible":"hidden",
        padding:"10px"
    }}
    sx={{
        w:"100%",
    }}>
        {id===null ?
        <>
        { googleCode===null?
            <Button
            iconName="Google"
            size="small"
            style={{
                alignSelf:"center"
            }}
            sx={{
                mt:"20px",
            }}
            onClick={()=>loginWithGoogle()}
            >
                sign in with google
            </Button>
            :
            <>
                <InputText
                style={{                                                                                                                                                                                                                                           
                    width:"100%"
                }}
                label="Enter your Google sheet title"
                name="title"
                size="small"
                data-testid="title"
                placeholder="Google sheet title"
                onChange={handleGoogleSheetTitleChange}
                error={GoogleError}
                msg={GoogleError!==null?GoogleError:"this is optional"}

                />
                <Button 
                size="small"
                onClick={() => {
                      GoogleSheetIntegration();  
                  }}
                >
                    connect
                </Button>
            </>
        }</> :
        <>
        <Type style={{alignSelf:"flex-start"}}>spreadsheet Title {data.spreadsheetTitle}</Type>
        <InputRadio
        style={{
            alignSelf:"flex-start"
        }}
        label={"enable or disable google sheet integration"}
        toggleButton={true}
        checked={enable}
        inputProps={{
            checked: enable,
            onChange: handleToggleChange,  // Call toggle handler when the user interacts with the toggle
        }}
        />
        <Button 
        size="small"
        onClick={() => {
              editGIntegration(isEnabled,id );  
          }}
        >
            edit
        </Button>
        </>
        }
    </Flex>
    :<></>}

    </Flex>
)
}
export default IntegrationCard
