function GetStartedForm ({handleSubmit,setUserEmail}){
    return(
        <form className="header-form" onSubmit={handleSubmit}>
        <div className="wrapper">
          <input
            type="email"
            id="someName"
            onChange={(e) => setUserEmail(e.target.value)}
            required
            placeholder="Enter your email"
          />
          <button type="submit">Get Started</button>
        </div>
      </form>
    )
}
export default GetStartedForm