function Arrow1 () {
    return(
        <svg
        className="arrow"
        width="118"
        height="98"
        viewBox="0 0 110 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2671_6218)">
          <path
            className="squiggle1"
            fill="none"
            stroke="black"
            strokeMiterlimit="1"
            strokeWidth="1"
            d="M45.2014 43.6377C51.1691 28.8827 68.4646 21.7379 91.4672 24.3269C88.5711 27.2798 82.7705 25.9182 81.7586 30.886C82.2019 31.4465 82.4662 32.0236 82.7032 32.0497C89.0438 31.2611 95.3998 30.5735 101.644 29.5291C102.146 29.4864 102.629 29.3134 103.043 29.0272C103.458 28.741 103.79 28.3516 104.008 27.8973C104.226 27.4431 104.322 26.9398 104.285 26.4374C104.249 25.9349 104.082 25.4506 103.801 25.0324C101.155 20.778 98.63 16.3379 93.7347 12.6778L93.217 18.9474C86.5164 18.7767 79.8866 18.0097 73.3409 18.4683C57.9429 19.5581 45.9104 26.49 38.9171 40.7628C37.8159 43.0253 36.2823 43.6794 34.0461 44.2184C29.6051 45.1479 25.4625 47.1602 21.9864 50.0763C18.5104 52.9924 15.8087 56.7222 14.1213 60.934C12.7553 64.1658 11.4482 67.7712 14.9479 71.9521C15.0291 69.0026 15.6913 66.0981 16.8964 63.4049C18.1016 60.7116 19.8261 58.2825 21.9713 56.2565C26.0411 52.2737 30.6143 49.3031 36.3292 48.9037C38.2541 53.7487 39.5358 58.5993 42.0076 62.7255C44.5522 66.9199 47.7456 70.6844 51.469 73.8789C53.1153 75.3419 55.2294 76.1699 57.4314 76.2142C59.6334 76.2586 61.7791 75.5163 63.483 74.1208C66.8143 71.5124 67.8802 67.1894 65.9793 62.4084C63.056 55.1409 57.4744 49.2596 50.3695 45.9607C48.6845 45.0525 46.9251 44.3799 45.2014 43.6377ZM43.8407 49.0592C47.4272 50.0726 50.755 51.8428 53.5997 54.2507C56.4444 56.6586 58.7401 59.6482 60.332 63.0181C61.4193 65.1973 62.1268 67.6285 59.6566 69.3385C57.5362 70.7993 55.4683 69.656 53.8711 68.2041C48.3738 63.1999 44.8347 56.4041 43.8862 49.0309L43.8407 49.0592Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_2671_6218">
            <rect
              width="109.089"
              height="47.5432"
              fill="white"
              transform="matrix(0.849147 -0.528156 -0.528156 -0.849147 25.1094 97.9873)"
            />
          </clipPath>
        </defs>
      </svg>
    )
}
export default Arrow1 ;