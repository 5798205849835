import img1 from "assets/1.CONFIGURE.svg";
import img2 from "assets/2.Copy.svg";
import img3 from "assets/3.Inbox.svg";

function SecondSection({ getStarted }) {
  const steps = [
    {
      img: img1,
      title: "1. CONFIGURE",
      description:
        "Our automated tool lets you configure and personalize forms with the click of a button.",
    },
    {
      img: img2,
      title: "2. COPY",
      description:
        "Once your form is setup, simply copy and paste the endpoint code to your website.",
    },
    {
      img: img3,
      title: "3. CHECK YOUR INBOX",
      description:
        "Your form is ready to go. Messages from your website will be sent to your email.",
    },
  ];

  return (
    <div className="secondDiv">
      <p className="secondDiv-div-P">Web forms in 3 easy steps</p>
      <p className="secondDiv-div-P2">3 simple steps to work</p>
      <button className="secondDiv-div-btn" onClick={getStarted}>
        Get started
      </button>
      <div className="secondDiv-div-row">
        {steps.map((step, index) => (
          <div className="secondDiv-div-row-div" key={index}>
            <img
              className="secondDiv-div-row-div-img"
              src={step.img}
              alt={`Step ${index + 1}: ${step.title}`}
            />
            <p className="secondDiv-div-row-div-P">{step.title}</p>
            <p className="secondDiv-div-row-div-P2">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SecondSection;
