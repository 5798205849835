import Arrow from "../../HomePageSvg/Arrow"
import CopyButton from "../../HomePageSvg/CopyButton"
// import img2 from "assets/webContentImg1.svg";

function FirstImage({setClick,handleClick,click}){
    return(
        <div className="header-img-container">
        <img className="content-img" src={"https://forms-easy.com/assets/webContentImg1.svg"} alt="copy form api"/>
        <div className="div">
          <div className="click-magic">Click To See Magic ✨</div>
            <Arrow/>
            <CopyButton  
            setClick={setClick}
            click={1}/>
          </div>
          <div
          onMouseOver={()=>handleClick(2)}
          className={`Endpoint-copied ${click === 1 ? " visible" : " hidden"}` }
          >
          Endpoint copied to clipboard 👏
        </div>
      </div>
    )
}
export default FirstImage