import React, { useState } from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";
import img0 from "assets/footerLog.svg";
import { useAuth } from "services/auth";
import { useProfile } from "views/Account/provider";
import { Box, Button, Divider, Flex, Type } from "ui";
import styled from "styled-components";
import bgImg from "assets/footerBackground.svg";
import { format } from "date-fns";

const Container = styled.div`
  background-image: url(${bgImg});
  background-position: center -200px;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 280px;
  @media screen and (max-width: 760px) {
    background-position: center -100px;
    padding-top: 60px;
  }
  article {
    border-radius: 10px;
    max-width: 1600px;
    width: 92vw;
    input {
      &:active,
      &:focus {
        outline: none;
      }
      padding-left: 15px;
    }
    @media screen and (max-width: 760px) {
      input {
        width: 200px;
        font-size: 15px;
      }
      button {
        font-size: 14px;
        letter-spacing: -0.05em;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
`;

function Footer() {
  let navigate = useNavigate();
  let auth = useAuth();

  const [name, setName] = useState("");
  const { setEmail } = useProfile();

  const handleClick = () => {
    if (auth.user) return navigate("/dashboard");

    setEmail(name);
    navigate("/login");
  };

  const handleChange = (e) => setName(e.target.value);

  return (
    <Container>
      <Flex
        as="article"
        jc="space-between"
        dir={["row", "_", "_", "column"]}
        gap={30}
        sx={{ px: [100, 50, 20, 20], py: 60, bgc: "#cde026" }}
      >
        <img src={img0} alt="" width="220" />

        <Flex sx={{ bgc: "white", p: 6, br: 999 }}>
          <input
            type="email"
            onChange={handleChange}
            placeholder="Enter your email"
          />
          <Button sx={{ br: 999, p: 25 }} onClick={handleClick}>
            Get Started
          </Button>
        </Flex>
      </Flex>

      <Box
        as="article"
        sx={{
          w: "80vw",
          mxw: 1600,
          mt: [150, 150, 150, 60],
          mb: 20,
          ta: "center",
        }}
        className="ending"
      >
        <Divider border="gray500" sx={{ op: 0.4 }} />
        <Type>
          © {format(new Date(), "yyyy")} Lift Media. All rights reserved.
        </Type>
      </Box>
    </Container>
  );
}

export default Footer;
