import { useEffect, useState } from "react";
import { Box, Button, Flex, Icon, Link } from "ui";
import UserAccount from "views/Home/header/UserAccount";
import SubmissionForm from "views/submitionForm/form";
import Modal from "ui/Modal";
import SectionNav from "./layout/SectionNav";
import { useProfile } from "views/Account/provider";
import SiteNav from "./layout/SiteNav";

function Layout({ children, sectionNav, SectionHeader, admin }) {
  const [openForm, setOpenForm] = useState(false);

  const { fetchProfile } = useProfile();

  useEffect(() => {
    const getProfile=async(e) => {  
      try { 
      await fetchProfile();
      } catch (e) {
      console.log(e);
      }
    };
    getProfile();
  }, [fetchProfile]);

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <>
      <Modal onClose={() => setOpenForm(false)} show={openForm}>
        <SubmissionForm onSuccess={handleClose} onCancel={handleClose} />
      </Modal>

      <Flex
        jc="space-between"
        sx={{
          w: "100%",
          h: "60px",
          px: 25,
          border: ["gray300", 2, "solid", 0, "b"],
          mxw: "100vw",
          of: "hidden",
        }}
      >
        <Box as={Link} to="/dashboard" sx={{ mnw: [200, 200, 200, "auto"], ml: [0, 0, 0, 20] }}>
          <Icon name="FormEasyIcon" w={27} />
        </Box>

        <SiteNav admin={admin} />

        <Flex gap={20} jc="flex-end" sx={{ minw: 200 }}>
          {!admin ? (
            <Button size="small" onClick={() => setOpenForm(true)} iconName="CreateForm">
              Create a form
            </Button>
          ) : (
            <Icon name="Notification" />
          )}

          <UserAccount />
        </Flex>
      </Flex>

      <Box  sx={{ mxw: 1200, mx: "auto", mt: 60 ,p:'20px'}}>
        {SectionHeader && <SectionHeader />}
        {Boolean(sectionNav) && <SectionNav nav={sectionNav} />}
        {children}
      </Box>
    </>
  );
}
export default Layout;
