import React from 'react';

const imageUrls = [
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png",
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png",
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png",
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png",
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png",
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png",
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png"
];

function FirstSection() {
  return (
    <div className="firstDiv">
      <p className="firstDiv-P">
        More than 200+ companies trusted us worldwide
      </p>
      <div className="slider">
        <div className="slide-track">
          {imageUrls.concat(imageUrls).map((url, index) => (
            <div className="slide" key={index}>
              <img
                src={url}
                height="50"
                width="125"
                alt={`Company logo ${index}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FirstSection;
