import { Flex, Type } from "ui";

export default function ThemeCard({
    src, title, isSelected, onSelect 
}) {


  return (
    <Flex
    dir="column"
    gap="2px"
    onClick={onSelect}
    sx={{
        br: 10,
        px: 20,
        py :10,
        bgc: isSelected ? "#d6d8f0" : "#f2f3f9",
        cursor: "pointer",
        transition: "background-color 0.3s",
        "&:hover": {
            bgc: "#e0e2f7",
          },
  
    }}
    >
        <img src={src} alt={`${title} theme`}/>
        <Type>{title}</Type>
    </Flex>
  );
}
