import { useCallback, useEffect, useState } from "react";

import {
  FlexStyled,
  StyledBox,
  AlignedButton,
  SecondRowItem,
} from "../../../Components";
import {
  Flex,
  InputRadio,
  InputText,
  Link,
  Select,
  Type,
} from "ui";



function Processing({selectedUser,edit, selectedForm}) {
  const [emailNotifications, setEmailNotifications] = useState(
    selectedForm?.enableNotification
  );
  const [redirectUrl, setRedirectUrl] = useState(selectedForm?.redirectURL);
  const [notificationEmailId, setNotificationEmailId] = useState(selectedForm?.notificationEmailId);
  const [primaryVerifiedEmailId,setPrimaryVerifiedEmailId] =useState(selectedUser.emails.find(email => email.email === selectedUser.primaryVerifiedEmail)?.id)
  const [error, setError] = useState([]);

  useEffect(()=>{
    setRedirectUrl(selectedForm?.redirectURL)
    setNotificationEmailId(selectedForm?.notificationEmailId)
  },[selectedForm])

  const handleRedirectUrlChange = useCallback((event) => {
    setRedirectUrl(event.target.value);
  }, []);

  function isValidUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const handleEdit =async(e) => {  
    e.preventDefault();
      const data = {
        enableNotification: emailNotifications,
        notificationEmailId:notificationEmailId
      };
    if (isValidUrl(redirectUrl)||redirectUrl===""||redirectUrl==null){
      redirectUrl!==""?data.redirectURL=redirectUrl:data.redirectURL=null 
      try {
        await edit(data);
        setError([])
      } catch (e) {
        setError(e.data.message)
      }
    }else{
      setError(['enter a valid url'])
    }
  };

  return (
    <>
      <FlexStyled>
        <InputRadio
          toggleButton={true}
          checked={emailNotifications}
          inputProps={{
            checked: emailNotifications,
            onChange: () => setEmailNotifications(!emailNotifications),
          }}
        />
        <StyledBox>
          <Type>Email Notifications</Type>
          <Type variant="subtitle">
            Enable or disable sending notification emails. Submissions will
            still be saved to the archive and dispatched to plugins.
          </Type>
        </StyledBox>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Target Email</Type>
          <Type variant="subtitle">
            Where to send submissions. To add a new email address, visit
            the <Link to = '/account'>account page.</Link>
          </Type>
        </StyledBox>
        <SecondRowItem>
          <Select
              data={selectedUser?.emails}
              mapData={{ value: "id", label: "email" }}
              name="target emails"
              size="medium"
              search={false}
              onChange={({ data }) => setNotificationEmailId(data.id)}
              value={notificationEmailId!==null?notificationEmailId:primaryVerifiedEmailId}
              defaultValue={notificationEmailId!==null?notificationEmailId:primaryVerifiedEmailId}
              sx={{
                w:"250px",
              }}
            />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Redirect</Type>
          <Type variant="subtitle">
            After successful submit, where should the user be redirected? 
          </Type>
        </StyledBox>
        <SecondRowItem>
        <InputText
          type="text"
          value={redirectUrl??""}
          placeholder={selectedForm?.redirectURL}
          size="medium"
          sx={{
            w: "250px",
            mb: "0px",
          }}
          onChange={handleRedirectUrlChange}
        />
        </SecondRowItem>
      </FlexStyled>
      <AlignedButton onClick={(e) => handleEdit(e)}>save</AlignedButton>



      <Flex
        role="alert"
        sx={{
          d: error.length ? "block" : "none",
          p: 5,
          bgc: "redBg",
          w: "100%",
          border: ["red", 10, "solid", 5, "r"],
          my: 10,
        }}
      >
        {error.map((err,index) => (
          <Type key={index}>{err}</Type>
        ))}
      </Flex>
    </>
  );
}
export default Processing;
