import Layout from "layout/Layout";
import styled from "styled-components";
import { Box, Flex, Icon, Link } from "ui";
import SettingsWithBackground from "../../assets/SettingsWithBackgound.svg";
import { useEffect, useState } from "react";
import { useSubmissionForm } from "./provider";
import SetupFormSection from "./SetupFormSection";
import SubmisissionFormList from "./submisionFormList";
import FormSettings from "./formSettings/FormSettings";
import { useParams } from "react-router-dom";
import Analytics from "./Analytics/Analytics";
import Exports from "views/Exports/Exports";
import { useUtils } from "services/utilsProvider";
import Integrations from "views/Integrations/Integrations";

const StyledFlex = styled(Flex)`
  padding: 0px 8px;
  gap: 3px;
  color: ${(props) => props.theme.color.black};
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 12px;
  background-color: ${(props) => props.theme.color.gray300};
  border-radius: 20px;
  font-weight: 400;
  cursor: pointer;
`;
const CustomLink = styled(Link)`
  color: ${(props) => props.theme.color.gray600};
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
  padding: 12px 20px;
  &:hover {
    color: ${(props) => props.theme.color.darkBlue};
    background-color: ${(props) => props.theme.color.gray400};
    border-radius: 6px;
  }
  &.selected {
    color: ${(props) => props.theme.color.darkBlue};
    background-color: ${(props) => props.theme.color.primary+ '22'};
    border-radius: 6px;
  }
`;

function FormDetails() {
  const tabs = ["Submissions", "Setup", "Analytics", "Settings","Exports","Connect"];
  const [activeTab, setactiveTab] = useState(tabs[0]);
  const {
    state: { selected },
    fetchOne,
  } = useSubmissionForm();

  const { formId, projectId } = useParams();
  const { notify } = useUtils();
  const copyToClipboard = (formUid) => {
    navigator.clipboard
      .writeText(formUid)
      .then(() => {
        notify('copied','success');
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };
  useEffect(() => {
    const getForm=async(e) => {  
      try {
      await fetchOne(projectId, formId);
      } catch (e) {
      console.log(e);
      }
    };
    if (formId && projectId) {
      getForm();
    }
  }, [projectId, formId, fetchOne]);

  return (
    <Layout>
      {formId && (
        <>
          <Flex gap="10" ai="center" jc="flex-start" sx={{ p: "0px 20px" }}>
            <img src={SettingsWithBackground} alt="settings" />
            <Box>
              <StyledFlex onClick={()=>{copyToClipboard(selected?.items?.uniqueId)}}>
                ID {selected?.items?.uniqueId}
                <Icon name={"Copy2"} w="16" />
              </StyledFlex>
            </Box>
          </Flex>
          <Flex
            gap="20"
            jc="space-between"
            sx={{ bgc: "gray200", my: 30, p: "6px", br: 6,of:"auto" }}
          >
            {tabs.map((tab, index) => (
              <CustomLink
                key={index}
                className={activeTab === tab ? "selected" : ""}
                onClick={() => setactiveTab(tab)}
              >
                {tab}
              </CustomLink>
            ))}
          </Flex>
          {activeTab === "Submissions" ? (
            <SubmisissionFormList selectedForm={formId} setactiveTab={setactiveTab} tabs={tabs}/>
          ) :
          activeTab === "Setup" ? (
            <SetupFormSection selectedForm={selected?.items?.uniqueId} />
          ) : activeTab === "Settings" ? (
            <FormSettings />
          ) : activeTab === "Analytics" ? (
            <Analytics />
          ) : activeTab === "Exports" ? (
            <Exports />
          ) : activeTab === "Connect" ? (
            < Integrations />
          ):(
            <></>
          )}
        </>
      )}
    </Layout>
  );
}
export default FormDetails;
