// import img4 from "assets/pasetedhere1.svg";
import '../header.css'

function SecondImage({setClick}){
    return (
        <div className="header-img-container">
        <img className="content-img" src={"https://forms-easy.com/assets/pasetedhere1.svg"} alt="paste form api"/>
        <button className="pastebutton" onClick={(e) => setClick(3)}>
          "click to paste here"
        </button>
      </div>
    )
}
export default SecondImage;