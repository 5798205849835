import { Box, Button, Flex, Icon, InputText, Loading, Type } from "ui";
import Dialog from "ui/Dialog";
import formal from "assets/formal.svg";
import fancy from "assets/fancy.svg";
import custom from "assets/custom.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import ThemeCard from "./ThemeCard";
import { CustomLink ,StyledButton } from "./Components";
import MonacoEditor from '@monaco-editor/react';
const Handlebars = require('handlebars');



export default function CustomEmail({
 selectedForm,
 logoURL,
 setCustomEmail,
 customEmail,
 ARS,
 globalTemplates,
 customTemplate,
 FetchglobalTemplate,
 selectedGlobalTheme,
 FetchcustomTemplate,
 editcustomTemplate,
 editARS,
 notify,
 UploadFile,
 uploadImageToS3
}) {

    const [selectedTheme, setSelectedTheme] = useState(ARS.activeTemplateType === 'global'
        ? { id: ARS?.activeTemplateId, name: ARS?.activeTemplateName }
        : { id: ARS?.activeTemplateId, name: ARS?.activeTemplateType });
    const [AllTemplates, setAllTemplates] = useState([ ...globalTemplates,
        { ...customTemplate, name: "custom" }, ]);
    const [activeTab, setActiveTab] = useState("Code");
    const [codeInput, setCodeInput] = useState(customTemplate?.htmlPart);
    const [message, setMessage] = useState(ARS?.customMessage);
    const [subject, setSubject] = useState(ARS?.emailSubject);
    const [logo, setLogo] = useState(ARS?.logoUrl);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);

// Data to inject into the Handlebars template
    const data = useMemo(        
        () => ({
        logoUrl:logo,
        customMessage: message?message:ARS?.customMessage,
        entries: [
            { key: "name", value: "aa" },
            { key: "email", value: "aa-pou@gmail.com" },
        ],
        }),
        [ARS,message,logo]
    );
    const handleCodeChange = (value) => {
        setCodeInput(value);
    };
    const handleThemeSelect = (themeId,themeName) => {
        setSelectedTheme({id:themeId,name:themeName});
    };
    useEffect(()=>{
        setLogo(ARS?.logoUrl)
        setCodeInput(customTemplate?.htmlPart)
        setSubject(ARS?.emailSubject)
        setMessage(ARS?.customMessage)
        setSelectedTheme(ARS.activeTemplateType === 'global'
            ? { id: ARS?.activeTemplateId, name: ARS?.activeTemplateName }
            : { id: ARS?.activeTemplateId, name: ARS?.activeTemplateType })
        setAllTemplates([ ...globalTemplates,
            { ...customTemplate, name: "custom" }, ])
    },[customTemplate,ARS,globalTemplates])

    useEffect(()=>{
        if(selectedTheme.name!=="custom"){
        const getglobalTemplate =async(e) => {  
            try {
            await FetchglobalTemplate(selectedTheme.id);
            } catch (e) {
            console.log(e);
            }
        };
        getglobalTemplate();
        }
    },[FetchglobalTemplate,selectedTheme,selectedForm])

    const compileHandlebars = useCallback((code, data) => {
        const template = Handlebars.compile(code);      
        return template(data);
    }, []);

    useEffect(() => {
        setLoading(true)
        const observerCallback = () => {
            if (Object.keys(selectedGlobalTheme).length !== 0 && selectedTheme.name !== "custom") {
                const finalHtml = compileHandlebars(selectedGlobalTheme?.htmlPart, data);
                // console.log(selectedGlobalTheme?.htmlPart);
                
                // console.log(finalHtml);
                // console.log("Data object:", data);
                
                setTimeout(() => {
                    const targetElement = document.getElementById("div");
                    if (targetElement) {
                        targetElement.innerHTML = finalHtml;
                    }
                }, 0);
            }
        };
    
        const observer = new ResizeObserver(observerCallback);
        const targetElement = document.getElementById("div");
        setLoading(false)
        if (targetElement) {
            observer.observe(targetElement);
        }
    
        return () => {
            if (targetElement) observer.unobserve(targetElement);
        };
    }, [selectedGlobalTheme, data, selectedTheme, compileHandlebars]);
    
    const handleMessageChange = useCallback((event) => {
        setMessage(event.target.value);
    }, []);

    const handleSubjectChange = useCallback((event) => {
        setSubject(event.target.value);
    }, []);
    
    const EditARS =async(e) => {  
        const isSubjectValid = subject.trim().length >= 1 && subject.trim().length <= 64;
        const isMessageValid = message.trim().length >= 1 && message.trim().length <= 128;
        if (!isSubjectValid || !isMessageValid) {
            const errors = [];
            if (!isSubjectValid) {
                if (subject.trim().length === 0) {
                    errors.push('Subject cannot be empty');
                } else {
                    errors.push('Subject must be between 1 and 64 characters');
                }
            }
            if (!isMessageValid) {
                if (message.trim().length === 0) {
                    errors.push('Message cannot be empty');
                } else {
                    errors.push('Message must be between 1 and 128 characters');
                }
            }
            setError(errors);
            return;
        }
        const data = {
            emailSubject: subject,
            customMessage: message,
            logoUrl:logoURL?logoURL:ARS.logoURL,
            activeTemplateId:selectedTheme?.id
        };
        try {
        await editARS(data,selectedForm?.id);
        if(selectedTheme.name!=="custom"){
        notify(' edited successfully','success')
        }
        setError([])
        } catch (e) {
            setError(e.data.message)
        }
        if(selectedTheme.name==="custom"){
            const data = {
                "htmlPart": codeInput
              }
              
            try {
                await editcustomTemplate(data,selectedForm?.id);
                notify(' edited successfully','success')
                setError([])
            } catch (e) {
                setError(e.data.message)
            }
        }
    };
    const uploadFile = async (file) => {
        setError([])
        if(file!==null){
        const data = {
            mimeType:file.type
        };
        try {
            const uploadResponse = await UploadFile(data,selectedForm?.id);
            if (uploadResponse && uploadResponse.uploadUrl) {
                setLoading(true)
                const signedUrl = uploadResponse.uploadUrl;
                const fileUploadResponse = await uploadImageToS3(file, signedUrl);
                if (fileUploadResponse.ok) {
                    setLogo(signedUrl.split("?")[0])
                    setLoading(false)
                    console.log("Image uploaded successfully!");
                } else {
                    console.error("Image upload failed:", fileUploadResponse);
                }
            } else {
                throw new Error("Failed to generate upload URL");
            }
        } catch (e) {
            console.log(e);
            setError(e.data.message)
        }
    }
      };

  return (
    <Dialog
    show={customEmail}
    onClose={() => setCustomEmail(false)}
  > 
    <Box
    sx={{
        maxh: "700px",
        w:["900px","_","100%","_"],
        of:"auto",
    }}>
        <Flex
        sx={{
            p:"20px 10px"
        }}
        ai="center"
        jc="flex-start">
            <Type 
            sx={{
                fw:"700",
                fs:"20px"
            }}>
                Customize your notification template
            </Type>
        </Flex>
        <Flex
        role="alert"
        sx={{
          d: error.length ? "block" : "none",
          p: 5,
          bgc: "redBg",
          w: "100%",
          border: ["red", 10, "solid", 5, "r"],
          my: 10,
        }}
      >
        {error.map((err,index) => (
          <Type key={index}>{err}</Type>
        ))}
      </Flex>
        <Flex
    
        jc="flex-start"
        ai="flex-start"
        dir={["row","_","column","_"]}
        sx={{
            pos:"relative",
            w:"100%"
        }}
        >
            <Flex
            dir="column"
            jc="flex-start"
            ai="flex-start"
            gap="5px"
            sx={{
                p:"10px",
                bgc:"#e7e8f5",
                w:["50%","_","100%","_"],
                pb:"50px"
            }}>
                <Type
                sx={{
                    fw:"700",
                }}>
                    Customize your notification template
                </Type>
                <Type
                sx={{
                    fs:"14px",
                }}>
                    Customize your notification template
                    Emails in notification list will get emails on new 
                    submission from based on this configuration
                </Type>
                <Type
                sx={{
                    fw:"700",
                }}>
                    Select theme
                </Type>
                <Flex 
                jc="space-around"
                sx={{
                    w:"100%",
                    py:"10px"
                }}>
                 {AllTemplates.map((template) => (
                <ThemeCard
                key={template.id}
                src={template.name==='Formal'?formal: template.name === "Fancy" ? fancy : custom}
                title={template.name}
                isSelected={selectedTheme.id === template.id}
                onSelect={() => handleThemeSelect(template.id,template.name)}
                />
                ))}
                </Flex>
                <Type
                    sx={{
                        fw:"700",
                    }}>
                        Customize content
                </Type>
                <Type
                sx={{
                    fs:"14px",
                }}>
                    Override default content with your own
                </Type>
                <InputText
                sx={{
                    w:"100%"
                }} 
                value={subject}
                onChange={handleSubjectChange}
                size="small"
                label="Subject"
                type="text"
                msg="Enter a subject for the email (1–64 characters)."
                inputProps={{
                    // defaultValue: ARS?.emailSubject
                }}
                />
                <InputText
                sx={{
                    w:"100%"
                }}
                value={message}
                onChange={handleMessageChange}
                size="small"
                label="Message"
                msg="Enter a message for the email (1–128 characters)."
                type="text"
                inputProps={{
                    // defaultValue:  ARS?.customMessage
                }}
                />
                <InputText
                sx={{
                    w:"100%"
                }}
                size="small"
                label="Logo"
                type="file"
                enableFileUpload
                defaultFile={logo}
                accept="image/*"
                onFileSelect={(file) =>{uploadFile(file)}}
                />
            </Flex> 
            <Flex
            dir="column"
            ai="center"
            jc="center"
            sx={{
                w:["50%","_","100%","_"],
                p:"40px 0 80px 0",
            }}>
                {loading&&<Loading/>}
                {selectedTheme.name==="custom"&& (
                <Flex sx={{ p: "4px", bgc: "gray200", br: "4px" ,maxw:"fit-content"}}>
                    <CustomLink
                        className={activeTab === "Preview" ? "selected" : ""}
                        onClick={() => setActiveTab("Preview")}
                    >
                        Preview
                    </CustomLink>
                    <CustomLink
                        className={activeTab === "Code"  ? "selected" : ""}
                        onClick={() => setActiveTab("Code")}
                    >
                        Code
                    </CustomLink>
                </Flex>)}
                <Flex
                dir="column"
                gap="1px"
                jc="flex-start"
                sx={{
                p: "1px",
                br: "8px",
                w: "90%",
                h: "450px",
                of: "auto", 
                bgc: "babyblue"
                }}
                >  
                    <Flex jc="space-between" sx={{bgc:"white",p:"10px",w:"100%",h:"10%"}}>
                    <Flex gap={8} jc="flex-start" >
                        <Box sx={{w:"12px",h:"12px",br:"50%",bgc:"#f984a4"}}/>
                        <Box sx={{w:"12px",h:"12px",br:"50%",bgc:"#fde36e"}}/>
                        <Box sx={{w:"12px",h:"12px",br:"50%",bgc:"#51de82"}}/>
                    </Flex>

                    {activeTab === "Code" && selectedTheme.name==="custom"&&(
                        <Type style={{alignSelf:"center"}}>Forms easy Code</Type>
                    )}
                    </Flex> 
                    {selectedTheme.name==="custom"?(
                        <>
                            {activeTab === "Code" && (
                                <>
                            <div style={{ width: '100%', height: '100%' }}>
                                 <MonacoEditor
                                height="100%"
                                language="javascript" // Syntax highlighting for JavaScript
                                theme="vs" // Light theme with white background
                                value={codeInput}
                                onChange={handleCodeChange}
                                options={{
                                    selectOnLineNumbers: true, // Select entire line on click
                                    lineNumbers: 'on', // Show line numbers
                                    renderLineHighlight: "gutter", // Highlight the active line
                                }}
                                editorDidMount={(editor) => {
                                    // Customize the focused line color (you can change this color as per your preference)
                                    editor.updateOptions({
                                        tokenColorCustomizations: {
                                            textMateRules: [
                                                {
                                                    scope: "markup.deleted",
                                                    settings: {
                                                        background: "#E0E5F2"// Focus line color (e.g., red)
                                                    }
                                                }
                                            ]
                                        }
                                    });
                                }}
                                />
                            </div>
                              <Flex jc="space-between" ai="center" sx={{p:"2px",w:"100%",h:"10%"}}>
                                <Flex
                                gap="2px">
                                    <StyledButton disabled={true} bg="darkBlue">entries</StyledButton>
                                    {/* <StyledButton disabled={true} bg="darkBlue" >email</StyledButton> */}
                                    <StyledButton disabled={true} bg="darkBlue">customMessage</StyledButton>
                                </Flex>
                                <Flex gap={1} jc="center" > 
                                    <Type sx={{w:"fit-content",p:"0px",m:"0px",ta:"end"}}>Dynamic tags</Type>
                                    <Icon name="WarningOctagon"  w = "18"/>
                                </Flex>
                              </Flex> 
                              </>
                            )}
        
                            {activeTab === "Preview" && (
                                <>
                                <Flex ai="flex-start" sx={{p:"1px"}}>
                                    <Icon name="WarningOctagon"  w = "18"/>
                                    <Type sx={{p:"5px",w:"100%"}} style={{}}>
                                        This is just a preview to demonstrate your template
                                        , dynamic tags have
                                        been replaced with your latest submission
                                    </Type>
                                </Flex>
                                <iframe
                                    title="Preview"
                                    srcDoc= {
                                        `${compileHandlebars(codeInput, data)}`
                                    }
                                    style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "none",
                                    zoom: 0.75,
                                    }}
                                />
                                </>
                            )}
                        </>

                    ):(
                    <div id="div" style={{width:"100%",height:"100%" ,overflow:"auto", zoom: 0.75,}}/>
                    )}
               </Flex>

  



                <Flex
                gap={10}
                sx={{
                    pos:"absolute",
                    bottom:"10px",
                    right:"10px"
                }}>
                    <Button 
                    variant="outlined"
                    onClick={() => 
                    setCustomEmail(false)
                    }>
                        Cancel
                    </Button>
                    <Button onClick={() => EditARS()}>Save</Button>
              
                </Flex>
            </Flex> 
            
        </Flex>
    </Box>
  </Dialog>
  );
}
