import { useCallback } from "react";
import { apiUrl as baseUrl } from "../config";
import { useToken } from "./useToken";
import { userStorage } from "./userStorage";

let refreshing = false;
let commandsQueue = [];

export const useRequest = () => {
  const auth = useToken();
  
  
  const queue = (...params) => {
    if (refreshing) {
      return new Promise((resolve, reject) => {
        const command = () => {
          queue(...params).then(resolve, reject);
        };
        commandsQueue.push(command);
      });
    }

    return new Promise(async (resolve, reject) => {
      try {
        if (refreshing) await new Promise((r) => setTimeout(r, 2000));

        if (params[3]) await checkToken(auth);

        const res = await request(...params, auth);

        if (res.status === 401) {
          if (!refreshing) {
            refreshing = true;
            await refreshToken(auth);
            refreshing = false;
            const retryRes = await request(...params, auth);
            resolve(await retryRes.json());
          } else {
            return new Promise((resolve, reject) => {
              const command = () => {
                queue(...params).then(resolve, reject);
              };
              commandsQueue.push(command);
            });
          }
        } else if (res.status === 403) {
          window.location.replace("/no-access");
          reject({ status: res.status, message: "Access Denied" });
        } else if (!res.ok) {
          const errorData = await res.json();
          reject({ status: res.status, message: errorData.message, data: errorData });
        } else {
          if (res.status === 204 || (res.status === 201 && res.headers.get('Content-Length') === '0')) {
            resolve({});
          } else {
            const resData = await res.json();
            resolve(resData);
          }
        }
      } catch (error) {
        console.error("Request error:", error);
        reject(error);
      }
    });
  };

  return useCallback(queue, [auth]);
};



export default function request(url, data = null, options = {},  withAuth = false,auth) {
  const defaultOptions = {
    method: data ? "POST" : "GET",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
    redirect: "follow",
    cache: "no-cache",
    credentials: "same-origin",
    ...options,
  };
  if (data) {
    defaultOptions.body = JSON.stringify(data);
  }  
  if (withAuth===true) {
    defaultOptions.headers.Authorization = `Bearer ${auth?.state?.accessToken}`;
  }

  return fetch(`${baseUrl}/${url}`, defaultOptions);
}


function checkToken(auth) {
  return new Promise(async (resolve, reject) => {
    const accessToken = auth?.state?.accessToken;
    if (!accessToken) return resolve();
  
    const jwt = atob(accessToken.split(".")[1]);
    const milliExp = jwt.exp * 1000;

    if (milliExp < Date.now()) {
      refreshing = true;
      try {
        await refreshToken(auth);
        refreshing = false;
        resolve();
      } catch (error) {
        reject(error);
      }
    } else {
      resolve(); 
    }
  });
}

async function refreshToken(auth) {
  try {
    const storedUser = userStorage.getUser();
    const refreshToken = storedUser?.refreshToken;
    if (!refreshToken) {
      console.warn("No refresh token found. Redirecting to login.");
      userStorage.removeUser();
      window.location.replace("/login");
      return;
    }
    const res = await fetch(`${baseUrl}/auth/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${refreshToken}`,
      },
      credentials: "include",
    });
    if (res.status === 401) {
      userStorage.removeUser();
      window.location.replace("/login");
      throw new Error("Unauthorized: Token refresh failed.");
    } else if (res.status === 201) {
      const data = await res.json();
      if (data?.accessToken && data?.refreshToken && data?.userId) {
        const updatedUser = {
          ...storedUser,
          id: data.userId,
          refreshToken: data.refreshToken,
        };
        const persist = !!localStorage.getItem("user");
        userStorage.setUser(updatedUser, persist);
        auth.dispatch({ type: "set_accessToken", payload: data?.accessToken });
      }
      return data;
    } else {
      throw new Error("Unexpected response status from token refresh.");
    }
    } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
    }
    }