import { ThemeProvider } from "styled-components";
import AuthProvider from "./auth";
import UtilsProvider from "./utilsProvider";
import theme from "../theme";
import ProfileProvider from "../views/Account/provider";
import FormProvider from "../../src/views/submitionForm/provider";
import ProjectProvider from "../../src/views/project/provider";
import SubmissionProvider from "views/submissions/provider";
import AdminProvider from "views/Admin/provider";
import ApiProvider from "views/api/provider";
import IntegrationsProvider from "views/Integrations/provider";
import TokenProvider from "./useToken";


export default function Providers({ children }) {
  return (
    
    <ThemeProvider theme={theme}>
      <UtilsProvider>
        <ApiProvider>
          <AdminProvider>
            <TokenProvider>
              <AuthProvider>
                <ProfileProvider>
                  <ProjectProvider>
                    <FormProvider>
                      <IntegrationsProvider>
                        <SubmissionProvider>{children}</SubmissionProvider>
                      </IntegrationsProvider>
                    </FormProvider>
                  </ProjectProvider>
                </ProfileProvider>
              </AuthProvider>
            </TokenProvider>
          </AdminProvider>
        </ApiProvider>
      </UtilsProvider>
    </ThemeProvider>
  );
}
