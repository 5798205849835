import Layout from "layout/Layout"
import { Box, Flex, Icon, Type } from "ui"
import SubmissionAnalytics from "./SubmissionAnalytics";
import UserAnalytics from "./UserAnalytics";
import { useEffect } from "react";
import { useAdmin } from "./provider";

function AdminDashboard (){
    const {
        state: { users },
        FetchUsersCount,
      } = useAdmin();
    
    useEffect(()=>{
        const getUsersCount=async(e) => {  
            try {
            await FetchUsersCount();
            } catch (e) {
            console.log(e);
            }
          };
        getUsersCount();
    },[FetchUsersCount])


    const boxData = [
        {
          label: 'Total User',
          value: users?.count,
          time :users?.since ? users?.since : "Update:2-22-2024",
          icon :"User2",
        },
        {
          label: 'Total Form',
          value: '12345',
          time :"Update:2-22-2024",
          icon :"File2",
        },
      ];
    return(
        <Layout admin>
            <Flex
            gap={30}
            jc="space-between"
            sx={{
                w:"100%",
                dir :["_","_","_","column"],
            }}>
                
                {boxData.map((data, index) => (
                <Box
                key={index}
                sx={{
                    w:["40%","_","_","100%"],
                    p:"10px",
                    br:"6px",
                    border: ["gray300", 1, "solid", 6, "tlrb"],
                }}>
                    <Flex
                    jc="space-between"
                    sx={{
                        mb:"10px",
                        ff:"secondary",
                    }}>
                        <Box>
                            <Type
                            var="p"
                            sx = {{
                                c:"gray500"
                            }}
                            >
                                {data.label}
                            </Type>
                            <Type 
                            var='h6'
                            >
                                {data.value}
                            </Type>
                        </Box>
                        <Flex
                        sx={{
                            bgc:"darkBlue",
                            p:"5px",
                            br:"10px"
                        }}>
                            <Icon 
                            name={data.icon}
                            w="20"
                            />
                        </Flex>
                    </Flex>
                    <Type 
                    var="p"
                    >
                        {data.time}
                    </Type>
                </Box>
                ))}

            </Flex>
            <SubmissionAnalytics/>
            <UserAnalytics/>
        </Layout>
    )

}
export default AdminDashboard