import Layout from "layout/Layout";
import { useEffect } from "react";
import { useProject } from "./provider";
import { Flex, Link, Pagination, TableContainer, Type } from "ui";
import { useSubmissionForm } from "views/submitionForm/provider";

function AllProjects() {
  const columns = [
    {
      label: "Title",
      name: "name",
      style: { textAlign: "center", fontWeight: "600", fontSize: "12px" },
    },
    {
      label: "createDate",
      name: "createDate",
      render: ({ createDate }) => (
        <Type
          style={{ textAlign: "center", fontWeight: "600", fontSize: "12px" }}
        >
          {formatDate(createDate, convertToCustomFormat)}
        </Type>
      ),
    },
    {
      label: "Project Details",
      name: "Project Details",
      render: ({ name, id }) => (
        <Flex gap={4}>
          <Link sx={{ ta: "center", fw: 600, fs: 12 }} to={`${id}`}>
            {name}
          </Link>
        </Flex>
      ),
    },
  ];
  const {
    state: { list: projects ,count, page,limit},
    dispatch,
    fetchList: fetchProjectList,
  } = useProject();
  const {
    dispatch:dispatchForm
  } = useSubmissionForm();

  useEffect(()=>{
    dispatchForm({
      type: "set_page",
      payload: 0,
    });
  },[dispatchForm])
  
  useEffect(() => {
    const getProjectList=async(e) => {  
      try {
      await fetchProjectList(page,limit);
      } catch (e) {
      console.log(e);
      }
    };
    getProjectList();
  }, [fetchProjectList,page,limit]);

  const formatDate = (dateStr, conversionFunction) => {
    const date = new Date(dateStr);
    return conversionFunction(date);
  };

  const convertToCustomFormat = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const handleChangePage = (newPage) =>
    dispatch({ type: "set_page", payload: newPage });

  return (
    <Layout>
      <Type
        var="h5"
        sx={{
          c: "type",
          fw: "700",
          ff: "secondary",
          text: "none",
          lh: "30px",
        }}
      >
        All Projects
      </Type>
      {projects && 
      <>
      <TableContainer data={projects} cols={columns} />
      <Pagination
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={limit}
      />
      </>
      }
    </Layout>
  );
}
export default AllProjects;
