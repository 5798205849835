import isPropValid from "@emotion/is-prop-valid";
import styled, { css } from "styled-components";

export default styled.div  
.withConfig({
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "show",
})`
  position: ${props => props.relative ? "absolute" : "fixed"};
  top: 0;
  left: 0;
  width: ${props => props.relative ? "100%" : "100vw"};
  height: ${props => props.relative ? "100%" : "100vh"};
  background-color: ${(p) => p.theme.color.black + "99"};
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${p => p.show && css`
    opacity: 1;
    pointer-events: all;
  `}
  transition: opacity .2s cubic-bezier(0, 0, 0.2, 1);
`;
