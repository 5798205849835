import React, { memo, useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import MoreOptions from "./tableContainer/MoreOptions";
import Loading from "./Loading";
import Responsive from "./Responsive";
import { currency, objByStr } from "../utilities/functions";
import Checkbox from "./Checkbox";
import Dropdown from "./core/Dropdown";
import Button from "./Button";
import InputText from "./InputText";
import Box from "./Box";
import Type from "./Type";
import stylesBlocks from "./core/stylesBuilder";
import Icon from "./Icon";
import Flex from "./Flex";
import ExportForm from "views/submitionForm/ExportForm";
import Select from "./select/Select";
import { useSubmissionForm } from "views/submitionForm/provider";
import { useParams } from "react-router-dom";
import TableHeader from "views/Admin/Users/TableHeader";
import { format } from "date-fns";
import { useUtils } from "services/utilsProvider";

const expandAnimation = keyframes`
  0% {
    height: 0px;
  }
  100% {
    height: 250px;
  }
`;

const ExpandedContentBox = styled(Box)`
  background-color: ${(p) => p.theme.color.gray100};
  padding: 20px;
  width: 100%;
  overflow: auto;
  height: 250px;
  transition: height 0.5s ease;
  animation: ${expandAnimation} 0.5s forwards;
`;
const Arrows = styled(Flex)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1px;
  background-color: ${(p) => p.theme.color.babyBlue};
  height: 30px;
  width: 25px;
  border-radius: 4px;
  cursor: pointer;
`;
const Table = styled.table`
  min-width: 100%;
  font-size: 16px;
  border-collapse: collapse;
  border: none;
  border-radius: 4px;
  background-color: white;

  tr td,
  tr th {
    background-color: ${(p) =>
      p.bg ? p.theme.color[p.bg] : p.theme.color.white};
  }
  thead {
    tr {
      th:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      th:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      th {
        font-size: 14px;
        text-align: left;
        font-weight: 600;
        color: ${(p) => p.theme.color.black};
        background-color: ${(p) => p.theme.color.babyBlue};
        padding: 10px;
        text-align: center;
      }
    }
  }
  tbody {
    .tr-spacer {
      height: 15px;
      border: none;
    }
    tr {
      transition: all 0.12s ease;
      border-bottom: 1px solid ${(p) => p.theme.color.babyBlue};
      border-top: 1px solid ${(p) => p.theme.color.babyBlue};

      td {
        border-left: 1px solid ${(p) => p.theme.color.babyBlue};
        border-right: 1px solid ${(p) => p.theme.color.babyBlue};
        padding: 10px;
      }
      &:hover {
        background-color: ${(p) => p.theme.color.gray100};
      }
      &.active {
        background: #f5f5f5;
        td {
          border-bottom: #e9e9e9 1px solid;
        }
      }
    }
  }
  ${stylesBlocks}
`;
const StyledButton = styled(Button)`
  text-transform: uppercase;
  min-width: fit-content;
  font-size: 12px;
  color: darkBlue;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 3px;
  &:hover,
  &:active,
  &.active {
    color: white;
    background-color: darkBlue;
    svg path {
      fill: ${(props) => props.theme.color.white};
    }
    svg.export path {
      fill: ${(props) => props.theme.color.darkBlue};
      stroke: ${(props) => props.theme.color.white};
    }
  }
`;
const StyledType = styled(Type)`
  padding: 0px 8px;
  color: ${(props) => props.theme.color.black};
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 12px;
  background-color: ${(props) => props.theme.color.gray300};
  border-radius: 20px;
  font-weight: 400;
  width: fit-content;
`;
const buttons = [
  {
    name: "Action",
    iconName: "More",
    label: "Action",
  },
  {
    name: "Filter",
    iconName: "Filter",
    label: "Filters",
  },
  {
    name: "Sort",
    iconName: "Sort",
    label: "Sort",
  },
  {
    name: "Export",
    iconName: "Export",
    label: "Export",
  },
];
const sortOptions = [
  {
    label: "Spam (first)",
    sortKey: "spamFirst",
  },
  {
    label: "Spam (last)",
    sortKey: "spamLast",
  },
  {
    label: "Data (Newest first)",
    sortKey: "newestFirst",
  },
  {
    label: "Data (Oldest first)",
    sortKey: "oldestFirst",
  },
  {
    label: "with attachments",
    sortKey: "withAttachment",
  },  {
    label: "without attachments",
    sortKey: "withoutAttachment",
  },
];
const ActionOptions = [
  {
    label: "Delete all",
    Key: "Delete all",
    iconName :"Xsign",
  },
  {
    label: "Mark as spam",
    Key: "Mark as spam",
    iconName :"WarningOctagon",
  },
  {
    label: "un Mark as spam",
    Key: "un Mark as spam",
    iconName :"WarningOctagon",
  }
];

function TableContainer({
  mixedHeader = false,
  numberOfRows,
  expandedContent = false,
  customHeader = false,
  empty = false,
  status = "",
  cols,
  textCols,
  data = [],
  tableProps = {},
  checkbox = false,
  showButton = false,
  moreOptions = false,
  textField = false,
  onChecked = () => {},
  children,
  compact = false,
  setactiveTab,
  tabs,
  ...rest
}) {
  const [checked, setChecked] = useState();
  const [all, setAll] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [openExportForm, setOpenExportForm] = useState(false);
  const [activeButton, setActiveButton] = useState("Sort");
  const [activeSortOption, setActiveSortOption] = useState();
  const [filterType, setFilterType] = useState();
  const [input, setInput] = useState("");
  const { notify } = useUtils();

  const { 
    state: { page, limit },
    fetchSubmissionList,
    deleteSubmission,
    EditSubmission
} = useSubmissionForm();
  const { formId, projectId } = useParams();


  useEffect(() => {
    setChecked(new Array(data.length).fill(false));
  }, [data]);

  useEffect(() => {
    onChecked(checked?.map((v, i) => (v ? data[i] : null)).filter((v) => v));
  }, [checked, onChecked, data]);

  function formatData(value, formatType, data) {
    if (!formatType || !value) return value;
    switch (formatType) {
      case "number":
        return <Box sx={{ ta: "right" }}>{value}</Box>;
      case "count":
        return value.length;
      case "date":
        return format(new Date(value), "MMMM dd, yyyy");
      case "datetime":
        return format(new Date(value), "MMMM dd, yyyy hh:mm aa");
      case "currency":
        return currency(value);
      case "link":
        return (
          <a href={value} target="_blank" rel="noreferrer">
            Download
          </a>
        );
      default:
        return formatType(value, data);
    }
  }

  const renderCell = (col, i) => {
    if (col.render) {
      const Col = col.render;
      return <Col {...data[i]} {...col.renderParams} index={i} />;
    }
    const Append = col.append || (() => {});
    return (
      <Box style={col.style} sx={col.sx || col.styles || {}}>
        <div>
          {formatData(objByStr(data[i], col.name), col.fn)}
          <Append {...data[i]} />
        </div>
      </Box>
    );
  };

  const handleCheckAll = () => {
    setActiveButton('Action');
    if (all) {
      setAll(false);
      setChecked((checked) => [...checked.fill(false)]);
    } else {
      setAll(true);
      setChecked((checked) => [...checked.fill(true)]);
    }
    // onChecked(checked)
  };

  const handleCheckbox = (index) => {
    setAll(false);
    setActiveButton('Action');
    var count = 0;
    setChecked((checked) => {
      const a = checked.map((c, i) => {
        var v = i === index ? !c : c;
        if (v) count++;
        return v;
      });
      if (count === checked.length) setAll(true);
      return a;
    });
  };

  const toggleRowExpansion = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleFilterType = useCallback(({ value }) => {
    setFilterType(value);
  }, []);

  const handleInputChange = useCallback((event) => {
    setInput(event.target.value);
  }, []);

  const handleFilter = async () => {
    setAll(false);
  
    // Validate filterType directly
    const isFilterTypeValid = /^[a-zA-Z0-9_.]{1,32}$/.test(filterType);
  
    if (!isFilterTypeValid) {
      notify('Filter Types should only contain numbers, letters, underscores, or periods, and be 32 characters or less');
      return;
    }
  
    const filter = {
      [`filter[${filterType}]`]: input,
    };
  
    const isValid = Object.entries(filter).every(([key, value]) => {
      return value.length <= 32;
    });
  
    if (!isValid) {
      notify('Filter values must be 32 characters or less');
      return;
    }
  
    await fetchSubmissionList(page, limit, projectId, formId, filter, "", "");
  };

  const handleSort = async (sortKey,label) => {
    setActiveSortOption(label)
    setAll(false)
    let order;
    let isSpam ;
    let hasAttachments ;
    if (!(sortKey === "spamFirst" || sortKey === "spamLast"||sortKey === "withAttachment"||sortKey === "withoutAttachment")) {
      if (sortKey === "newestFirst") {
        order = "desc";
      } else if (sortKey === "oldestFirst") {
        order = "asc";
      } 
      isSpam=false ;
      hasAttachments=null
    }else if(!(sortKey === "withAttachment"||sortKey === "withoutAttachment")){
      if(sortKey==="spamFirst"){
        order = "desc";
      }else{
        order = "asc";
      } 
      isSpam =true;
      hasAttachments=null
    }else {
      if(sortKey === "withAttachment"){
        hasAttachments=true
      }else{
        hasAttachments=false;
      }
      order='desc';
      isSpam=null

    }
    await fetchSubmissionList(page, limit, projectId, formId, {}, order,isSpam,hasAttachments);
  };
  const handleDeleteSubmission = async () => {
    const filteredRecords = data.filter((record, index) => checked[index]);
    const submissionIds = filteredRecords.map((record) => record.id);
    if(submissionIds.length!==0){
      await deleteSubmission(projectId, formId, {submissionIds:submissionIds});
      setAll(false)
    }

  };
  const handleEditSubmission = async (label) => {
    let isSpam =false;
    if(label==="Mark as spam")isSpam=true;
    const filteredRecords = data.filter((record, index) => checked[index]);
    const submissionIds = filteredRecords.map((record) => record.id);
    if(submissionIds.length!==0){
      await EditSubmission(projectId, formId, submissionIds ,{isSpam:isSpam});
      setAll(false)
    }

  };

  
  

  if (!data || !checked) return;

  return (
      <>
        {status === "fetching" && (
          <Box
            abs={{ t: 120, l: "50%" }}
            sx={{ tr: "translate(0, -50%)", z: 9999 }}
          >
            <Loading />
          </Box>
        )}
        <Responsive {...rest}>
          <Box sx={{ mnw: cols.length * 140 }} className="tableWrapper">
            <Table {...{ size: "small", ...tableProps }}>
              <thead>
                {customHeader && !mixedHeader ? (
                  <tr>
                    <td
                      colSpan={
                        cols.length +
                        (checkbox ? 1 : 0) +
                        (expandedContent ? 1 : 0)
                      }
                    >
                      <Box sx={{ br: "4px" }}>
                        <Flex
                        gap="15px"
                          jc="space-between"
                          ai="center"
                          sx={{ p: "5px" ,of:"auto"}}
                          style={{ backgroundColor: "#f2f3f9" }}
                        >
                          <Checkbox
                            size="small"
                            label="select all"
                            id="all"
                            checked={all}
                            inputProps={{
                              checked: all,
                              onChange: handleCheckAll,
                            }}
                            style={{
                              margin: "0px",
                              backgroundColor: "white",
                              color: "darkBlue",
                              padding: "3px",
                              borderRadius: "4px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            }}
                          />
                          <Flex gap="5" >
                            {buttons.map((button) => (
                              <StyledButton
                                key={button.iconName}
                                size="small"
                                iconName={button.iconName}
                                onClick={() => {
                                  handleButtonClick(button.name);
                                  if (button.name === "Export") {
                                    setOpenExportForm(true);
                                  }
                                  if(button.name==="Sort"){ 
                                    handleSort(activeSortOption?sortOptions.find(option => option.label === activeSortOption).sortKey:"oldestFirst",activeSortOption?activeSortOption:"Data (Oldest first)")
                                  }
                                }}
                                className={
                                  activeButton === button.name ? "active" : ""
                                }
                              >
                                {button.label}
                              </StyledButton>
                            ))}
                          </Flex>
                        </Flex>
                        <Flex
                          jc="flex-start"
                          gap="5"
                          sx={{ p: "5px" ,of:"auto"}}
                          style={{ backgroundColor: "#d7d9ed" }}
                        >
                          {activeButton === "Sort" ? (
                            <>
                              {sortOptions.map((option) => (
                                <StyledButton
                                  key={option.sortKey}
                                  size="small"
                                  onClick={() => handleSort(option.sortKey,option.label)}
                                  className={
                                    activeSortOption === option.label ? "active" : ""
                                  }
                                >
                                  {option.label}
                                </StyledButton>
                              ))}
                            </>
                          ) : activeButton === "Filter" ? (
                            <Flex gap={10}>
                              <Box
                                sx={{
                                  w: "200px",
                                }}
                              >
                                <Select
                                  data={cols?.filter(column => !column.name.startsWith('file'))}
                                  mapData={{ label: "label", value: "name" }}
                                  defaultValue={cols[0]?.name}
                                  value={filterType}
                                  onChange={handleFilterType}
                                  search={false}
                                  size="small"
                                />
                              </Box>
                              <InputText
                                size="small"
                                sx={{
                                  w: "200px",
                                  mb: "0px",
                                  bgc: "white",
                                }}
                                value={input}
                                onChange={handleInputChange}
                              />
                              <Button
                                size="small"
                                onClick={() => handleFilter()}
                              >
                                Apply
                              </Button>
                            </Flex>
                          ) :  activeButton === "Action" ? (
                            <>
                            {ActionOptions.map((option) => (
                              <StyledButton
                                iconName={option.iconName}
                                key={option.Key}
                                size="small"
                                onClick={() => {
                                  if(option.label==="Delete all")
                                    handleDeleteSubmission()
                                  else
                                    handleEditSubmission(option.label)
                                  }}
                              >
                                {option.label}
                              </StyledButton>
                            ))}
                          </>
                          ):<></>}
                        </Flex>
                      </Box>
                    </td>
                  </tr>
                ) : !customHeader && mixedHeader ? (
                  <TableHeader
                    all={all}
                    handleCheckAll={handleCheckAll}
                    checkbox={checkbox}
                    cols={cols}
                  />
                ) : (
                  <tr>
                    {checkbox && (
                      <th>
                        <Checkbox
                          size="small"
                          name="all"
                          value="all"
                          checked={all}
                          label="select all"
                          id="all"
                          inputProps={{
                            checked: all,
                            onChange: handleCheckAll,
                          }}
                        />
                      </th>
                    )}
                    {cols.map((col) => (
                      <th key={col.label} {...col.props}>
                        {col.filter ? (
                          <Dropdown content={col.filter} space={15}>
                            <span>
                              <Button
                                variant={col.filtered ? "outlined" : "text"}
                                iconName="angleDown"
                                bg="white"
                              >
                                {col.label}
                              </Button>
                            </span>
                          </Dropdown>
                        ) : (
                          col.label
                        )}
                      </th>
                    ))}
                    {textField &&
                      textCols.map((col,i) => {
                        return (
                          <th key={i} style={{ textAlign: "left" }}>{col.label}</th>
                        );
                      })}
                    {showButton && (
                      <th style={{ textAlign: "center" }}>Details</th>
                    )}
                    {moreOptions && <th></th>}
                    {expandedContent && <th></th>}
                  </tr>
                )}
              </thead>

              <tbody>
                {!empty ? (
                  data.map((row, i) => (
                    <React.Fragment key={i}>
                      {!compact && (
                        <tr key={`spacer${i}`} className="tr-spacer"></tr>
                      )}
                      {/* Spacer */}
                      <tr
                        key={`${row.id}${i}`}
                        className={checked[i] ? "active" : ""}
                      >
                        {checkbox && (
                          <td>
                            <Checkbox
                              size="small"
                              style={{ margin: "0px" }}
                              name={"row" + i}
                              value={row.id || ""}
                              id={row.id}
                              inputProps={{
                                checked: checked[i] || false,
                                onChange: () => handleCheckbox(i),
                              }}
                            />
                          </td>
                        )}
                        {cols.map((col) => (
                          <td key={col.label}>{renderCell(col, i)}</td>
                        ))}
                        {textField &&
                          textCols.map(
                            (col, index) =>
                              textField &&
                              col.inputName && (
                                <td key={col.name}>
                                  <InputText
                                    label={col.inputLabel && col.inputLabel}
                                    name={col.inputName && col.inputName}
                                    onChange={(v) => {
                                      data[index - 1][col.inputName] =
                                        v.target.value;
                                    }}
                                    inputProps={{
                                      type: col.inputType && col.inputType,
                                      label:
                                        col.inputName &&
                                        (data[i][col.inputName] !== 0
                                          ? data[i][col.inputName]
                                          : 0),
                                    }}
                                  />
                                </td>
                              )
                          )}

                        {showButton && (
                          <td>
                            <Button
                              style={{ width: "100%", minWidth: "125px" }}
                              className="transparent small"
                              onClick={() => {
                                showButton(row);
                              }}
                            >
                              View More
                            </Button>
                          </td>
                        )}
                        {moreOptions && (
                          <td>
                            <MoreOptions
                              data={row}
                              moreOptions={moreOptions}
                            />
                          </td>
                        )}
                        {expandedContent && (
                          <td style={{ position: "relative" }}>
                            <Arrows>
                              <Icon
                                name={"ArrowDownAndUp"}
                                w="10"
                                onClick={() => toggleRowExpansion(i)}
                              />
                            </Arrows>
                          </td>
                        )}
                      </tr>
                      {expandedRows.includes(i) && (
                        <tr>
                          <td
                            style={{ border: "none" }}
                            colSpan={
                              cols.length +
                              (checkbox ? 1 : 0) +
                              (expandedContent ? 1 : 0)
                            }
                          >
                            <ExpandedContentBox data={data[i]}>
                            <Flex jc="flex-start" gap={10}sx={{w:"100%"}} style={{flexWrap:"wrap"}}>
                            {Object.keys(data[i])
                            .filter(key => key !== 'id' && key !== 'origin')
                            .map((key) => (
                              <>
                                <InputText
                                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                                  value={data[i][key]}
                                  type="text"
                                  id="Name"
                                  size="small"
                                  inputProps={{
                                    required: true,
                                  }}
                                  sx={{
                                    minw: "30%",
                                  }}
                                />
                              </>

                            ))}  
                            </Flex>
                              <Type var="h6" sx={{ fw: "700" }}>
                                Automtically Captured
                              </Type>
                              <StyledType>origin {data[i].origin}</StyledType>
                            </ExpandedContentBox>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td align="center" colSpan={cols.length + 2}>
                      <Type>No data available</Type>
                    </td>
                  </tr>
                )}
                {numberOfRows && (
                  <tr>
                    <td align="center" colSpan={cols.length + 2}>
                      <Type>Total {data.length} exports</Type>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Box>
        </Responsive>
        <ExportForm
          setOpenExportForm={setOpenExportForm}
          openExportForm={openExportForm}
          setactiveTab={setactiveTab}
          tabs={tabs}
        />
      </>
  );
}

export default memo(TableContainer);
