import { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Button, Flex, Select } from "ui";
import ExportForm from "views/submitionForm/ExportForm";
import { useAdmin } from "../provider";

const StyledButton = styled(Button)`
  text-transform: uppercase;
  font-size: 12px;
  color: darkBlue;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 3px;
  &:hover,
  &:active ,
  &.active {
    color: white;
    background-color: darkBlue;
    svg path {
      fill: ${(props) => props.theme.color.white};
    }
    svg.export path {
      fill: ${(props) => props.theme.color.darkBlue};
      stroke: ${(props) => props.theme.color.white};
    }
  }
`;
const buttons = [
    {
      name: "Filter",
      iconName: "Filter",
      label: "Filters",
    },
    {
      name: "Sort",
      iconName: "Sort",
      label: "Sort",
    },
    {
      name: "Export",
      iconName: "Export",
      label: "Export",
    },
  ];
const sortOptions = [
  {
    label: "Data (Newest first)",
    sortKey: "newestFirst",
  },
  {
    label: "Data (Oldest first)",
    sortKey: "oldestFirst",
  },
];
function Header () {
    const [activeButton, setActiveButton] = useState('Sort');
    const [openExportForm, setOpenExportForm] = useState(false);
    const [list,setList] = useState([]);
   
    const {
      state: { usersList },
      FetchUsersList,
    } = useAdmin();
    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
      };

    useEffect(()=>{
      const getUsersList=async(e) => {  
        try {
        await FetchUsersList();
        } catch (e) {
        console.log(e);
        }
      };
      getUsersList();
    },[FetchUsersList])

    useEffect(()=>{
      const clonedList = usersList.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`,
      }));
    
      const updatedList = [ { id: "all", fullName: "All" },...clonedList];
      setList(updatedList);
    },[usersList])

    return(
      <>
      {!openExportForm ? (
        <Box sx={{ br: "4px" }}>
        <Flex
          jc="flex-end"
          ai="center"
          sx={{ p: "5px" }}
          style={{ backgroundColor: "#f2f3f9" }}
        >
         <Flex gap="5">
          {buttons.map((button) => (
            <StyledButton
              key={button.iconName}
              size="small"
              iconName={button.iconName}
              onClick={() => {
                handleButtonClick(button.name);
                if (button.name === "Export") {
                  setOpenExportForm(true);
                }
              }}
              className={activeButton === button.name ? "active" : ""}
            >
              {button.label}
            </StyledButton>
          ))}
        </Flex>
        </Flex>
        <Flex
          jc="flex-start"
          gap="5"
          sx={{ p: "5px" }}
          style={{ backgroundColor: "#d7d9ed" ,minHeight:"45px"}}
        >
          { activeButton==='Sort' ? (
            <>
               {sortOptions.map((option) => (
                <StyledButton
                  key={option.sortKey}
                  size="small"
                //   onClick={() => handleSort(option.sortKey)}
                >
                  {option.label}
                </StyledButton>
              ))}
            </>
          ):activeButton==='Filter' ? (
            <>
          <Flex 
          gap={10}
          >
             <Box
            sx={{
              w:"200px"
            }}>
              <Select
                data={list}
                mapData={{ label: "fullName", value: "id" }}
                defaultValue="all"
                // value={filterType}
                // onChange={handleFilterType}
                search={true}
                size="small"
              />
            </Box>
          
          </Flex>
          </>
          ):(<></>)}
        </Flex>
      </Box> ):(
        <ExportForm
        setOpenExportForm={setOpenExportForm}
        openExportForm={openExportForm}
        />
      )}
      </>
    );
}
export default Header