import { useState } from "react";
// import img11 from "assets/EndPoint.svg";
// import img12 from "assets/collect-sub.svg";
// import img13 from "assets/uploadFiles.svg";
// import img14 from "assets/response.svg";
// import img15 from "assets/reciveSend.svg";
// import img16 from "assets/export.svg";

function ThirdSection({ getStarted }) {
  const [selectedId, setSelectedId] = useState("1");

  
  const tabs = [
    { id: "1", label: "Endpoint", img: "https://forms-easy.com/assets/EndPoint.svg" },
    { id: "2", label: "Collect submission", img: "https://forms-easy.com/assets/collect-sub.svg" },
    { id: "3", label: "Upload files", img:  "https://forms-easy.com/assets/uploadFiles.svg" },
    { id: "4", label: "Auto Responses", img:  "https://forms-easy.com/assets/response.svg" },
    { id: "5", label: "Receive or Send", img:  "https://forms-easy.com/assets/reciveSend.svg" },
    { id: "6", label: "Export", img:  "https://forms-easy.com/assets/export.svg"  },
  ];

  const selectedTab = tabs.find((tab) => tab.id === selectedId);

  return (
    <div className="thirdDiv">
      <p className="thirdDiv-P">Everything you need to make your form work.</p>
      <p className="thirdDiv-P2">
        Make your form work in seconds without relying on dependencies, special
        libraries, etc.
      </p>
      <div className="thirdDiv-nav">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            id={tab.id}
            onClick={() => setSelectedId(tab.id)}
            className={`thirdDiv-nav-button ${selectedId === tab.id ? "active" : ""}`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      {selectedTab && (
        <div className="thirdDiv-img">
          <img
            src={selectedTab.img}
            alt={selectedTab.label}
            className="thirdDiv-img-web"
          />
          {/* <button className="thirdDiv-img-button" onClick={getStarted}>
            Get started
          </button> */}
        </div>
      )}
    </div>
  );
}

export default ThirdSection;
