import slide1 from "assets/slide1.svg";
import slide2 from "assets/slide2.svg";
import slide3 from "assets/slide3.svg";
import slide4 from "assets/slide4.svg";
import slide5 from "assets/slide5.svg";
import slide6 from "assets/slide6.svg";
import IntegrationsSvg from "../HomePageSvg/IntegrationsSvg";

const slides = [slide1, slide2, slide3, slide4, slide5, slide6];

const Slider = ({ slideClassName, slideImages,slideTrack ,slide}) => (
  <div className={slideClassName}>
    <div className={slideTrack}>
      {slideImages.map((image, index) => (
        <div className={slide} key={index}>
          <img src={image} alt={`slide-${index}`} />
        </div>
      ))}
    </div>
  </div>
);

function FifthSection() {
  return (
    <div className="fifthDiv">
      <div className="content">
        <button className="fifthDiv-btn">
          <IntegrationsSvg />
          Integrations
        </button>
        <p className="fifthDiv-P">Send Submissions to 3rd Parties.</p>
        <p className="fifthDiv-P2" style={{ marginBottom: 40 }}>
          Want to see your submissions in Google Sheets? Maybe you'd like to
          have a message for each new submission in Slack? Well, that's all
          possible with forms — easy and easy to set up. 1000+ apps available to
          integrate; the sky's the limit.
        </p>
      </div>

      <div className="sliders">
        <Slider slide="slide1" slideClassName="slider1" slideImages={slides} slideTrack="slide-track1"/>
        <Slider slide="slide2" slideClassName="slider2" slideImages={[slide4, slide2, slide3, slide1, slide4, slide2]} slideTrack="slide-track2"/>
        <Slider slide="slide1" slideClassName="slider1" slideImages={slides} slideTrack="slide-track1" />
      </div>
    </div>
  );
}

export default FifthSection;
