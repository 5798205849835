import {
  addDays,
  addMonths,
  format,
  nextMonday,
  previousMonday,
  startOfMonth,
  startOfYear,
  subDays,
  subWeeks,
} from "date-fns";
import { useCallback, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import theme from "theme";
import { Box, Select, Type } from "ui";
import { useSubmission } from "views/submissions/provider";

const demoCategories = [];

const initSeries = [
  {
    name: "Total Spam",
    data: [],
  },
  {
    name: "Total Submission",
    data: [],
  },
];

const chartOptions = {
  plotOptions: {
    bar: {
      borderRadius: 4,
      borderRadiusApplication: 'end',
      // borderRadiusWhenStacked: "last", // "all"/"last"
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  stroke: {
    show: false,
    width: 2,
    colors: 'white'
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return (
        '<div class="chart-label">' +
        "<span>Total Submissions: " +
        (series[0][dataPointIndex] + series[1][dataPointIndex]) +
        "<br /> Spam Submissions: " +
        series[0][dataPointIndex] +
        "</span>" +
        "</div>"
      );
    },
  },
  colors: [theme.color.red + "99", theme.color.primary + "cc"],
  dataLabels: {
    style: {
      colors: [theme.color.white + "bb", theme.color.white + "bb"],
    },
  },
  chart: {
    id: "bar",
    stacked: false,
    toolbar: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  xaxis: {
    labels: {
      style: {
        colors: "#666",
      },
    },
    categories: demoCategories,
  },
};

export default function SubmissionChart({ noSelect, statment, PeriodType }) {
  const [options, setOptions] = useState(chartOptions);
  const [series, setSeries] = useState(initSeries);
  const [periodType, setPeriodType] = useState(
    PeriodType ? PeriodType : "month"
  );

  const {
    state: { statistics },
    fetchStats,
  } = useSubmission();


  useEffect(() => {
    const params = {
      periodType: "",
      startDate: null,
      endDate: null,
    };

    switch (periodType) {
      case "week":
        params.periodType = "week";
        params.startDate = format(
          previousMonday(subWeeks(new Date(), 4)),
          "yyyy-MM-dd"
        );
        params.endDate = format(
          nextMonday(addDays(new Date(), -1)),
          "yyyy-MM-dd"
        );
        break;
      case "month":
        params.periodType = "month";
        params.startDate = format(startOfYear(new Date()), "yyyy-MM-dd");
        params.endDate = format(
          startOfMonth(addMonths(new Date(), 1)),
          "yyyy-MM-dd"
        );
        break;
      case "day":
        params.periodType = "day";
        params.startDate = format(subDays(new Date(), 6), "yyyy-MM-dd");
        params.endDate = format(addDays(new Date(), 1), "yyyy-MM-dd");
        break;
      default:
        params.periodType = "day";
        params.startDate = format(subDays(new Date(), 7), "yyyy-MM-dd");
        params.endDate = format(new Date(), "yyyy-MM-dd");
    }
    fetchStats(params);
  }, [periodType, fetchStats]);

  useEffect(() => {
    if (!(statistics.length && statistics[0][periodType])) return;
    let formatType = "MMM";
    if (periodType === "week" || periodType === "day") formatType = "MMM d";

    const categories = statistics
      .map((stat) => {
        return format(stat[periodType], formatType);
      })
      .reverse();
    const series = [
      {
        name: "Total Spam",
        data: statistics.map((stat) => stat.spam_count).reverse(),
      },
      {
        name: "Total Submission",
        data: statistics.map((stat) => stat.not_spam_count).reverse(),
      },
    ];

    setOptions({
      ...chartOptions,
      xaxis: {
        ...chartOptions.xaxis,
        categories,
      },
    });
    setSeries(series);
  }, [statistics, periodType]);

  const handlePeriodType = useCallback(({ value }) => {
    setPeriodType(value);
  }, []);

  if (!(options || series)) return;

  return (
    <Box relative sx={{ w: "100%" }}>
      {statment && (
        <Box abs={{ r: 8, t: [0, 0, 0, -55] }} sx={{ z: 9999 }}>
          <Type>{statment}</Type>
        </Box>
      )}
      {!noSelect && (
        <Box abs={{ r: 8, t: [0, 0, 0, -55] }} sx={{ w: 140, z: 9 }}>
          <Select
            data={[
              { label: "Month", value: "month" },
              { label: "Week", value: "week" },
              { label: "Day", value: "day" },
            ]}
            mapData={{ label: "label", value: "value" }}
            defaultValue="month"
            value={periodType}
            onChange={handlePeriodType}
            search={false}
            size="small"
          />
        </Box>
      )}
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height={380}
      />
    </Box>
  );
}
