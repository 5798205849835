// import img5 from "assets/pastedCode.svg";
import '../header.css'

function ThirdImage({handleClick}){
    return(
    <div  className="header-img-container" >
        <img className="content-img" src={"https://forms-easy.com/assets/pastedCode.svg"} alt="form api pasted"  />
        <div className="Endpoint-copied" onClick={()=>handleClick(4)}>Pasted into the code 🔥</div>
    </div>    
    )
}
export default ThirdImage