import styled from "styled-components";

const Span = styled.span.attrs((p) => ({
  active: undefined,
}))`
  display: none;
  @media only screen and (max-width: 768px) {
    display: ${(p) => (p.active ? "none" : "block")};
    border-top: ${(p) => p.theme.color.gray600} 3px solid;
    border-bottom: ${(p) => p.theme.color.gray600} 3px solid;
    width: 17px;
    height: 9px;
    background-color: #fff;
    position: absolute;
    top: 20px;
    left: 10px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      top: 9px;
      width: 17px;
      height: 3px;
      background-color: ${(p) => p.theme.color.gray600};
    }
  }
`;

export default function ToggleNav({active, setActive}) {
  return (
    <Span
      active={active}
      onClick={(e) => {
        e.stopPropagation();
        setActive((active) => !active);
      }}
    />
  );
}
