import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";
import isPropValid from "@emotion/is-prop-valid";

const Box = styled.div.withConfig({
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  max-width: ${(props) => props.$w ? props.$w + "px" : "auto"};
  ${stylesBlocks}
`;

export default Box;
