import { Flex, Type } from "ui";
import loginLogo from "assets/loginLogo.svg";

function LayoutMasked({ children, title, subtitle }) {
  return (
    <Flex dir="column" sx={{w: "100vw"}} style={{minHeight:"100vh", overflowX: "hidden" }}>
      <Flex dir="column">
        <img
          style={{
            width: "120px",
            height: "60px",
           marginBottom: "12px",
            marginTop: "20px",
          }}
          src={loginLogo}
          alt=""
        />
        <Type var="supTitle"sx={{px:"20px",ta:"center"}}>{title}</Type>
        <Type var="subTitle"sx={{px:"20px",ta:"center"}}>{subtitle}</Type>
      </Flex>
      {children}
    </Flex>
  );
}

export default LayoutMasked;
