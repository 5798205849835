import React from "react";
import { Box } from "ui";
import Footer from "./footer/Footer";
import HeaderLayout from "./header/HeaderLayout";
import "./homepage.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "services/auth";
import FirstSection from "./HomePageSections/FirstSection";
import SecondSection from "./HomePageSections/SecondSection";
import ThirdSection from "./HomePageSections/ThirdSection";
import FourthSection from "./HomePageSections/FourthSection";
import FifthSection from "./HomePageSections/FifthSection";
import SixthSection from "./HomePageSections/SixthSection";

function HomePage() {
  let navigate = useNavigate();
  const auth = useAuth();


  const getStarted = () => {
    if (auth.user) return navigate("/dashboard");
    navigate("/login");
  };

  return (
    <Box sx={{of: 'hidden', w: '100vw'}}>
      <HeaderLayout getStarted={getStarted}/>
      <FirstSection/>
      <SecondSection getStarted={getStarted}/>
      <ThirdSection getStarted={getStarted}/>
      <FourthSection getStarted={getStarted}/>
      <FifthSection/>
      <SixthSection/>
      <Footer />
    </Box>
  );
}

export default HomePage;
