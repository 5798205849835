import React, { useEffect, useState } from "react";
import "./header.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "services/auth";
import { useProfile } from "views/Account/provider";
import FirstImage from "./HeaderLayoutSections/FirstImage";
import SecondImage from "./HeaderLayoutSections/SecondImage";
import ThirdImage from "./HeaderLayoutSections/thirdImage";
import FourthImage from "./HeaderLayoutSections/fourthImage";
import FifthImage from "./HeaderLayoutSections/FifthImage";
import SixthImage from "./HeaderLayoutSections/SixthImage";
import Nav from "./HeaderLayoutSections/Nav";
import MainTitle from "./HeaderLayoutSections/MainTitle";
import SubTitle from "./HeaderLayoutSections/SubTitle";
import GetStartedForm from "./HeaderLayoutSections/GetStartedForm";


function HeaderLayout({getStarted}) {
  const [click, setClick] = useState(0);
  const [sen, setSen] = useState(false);

  const [userEmail, setUserEmail] = useState("");
  const { setEmail } = useProfile();

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmail(userEmail); 
    getStarted(); 
  };

  const handleClick = (moveTo) => {
    setTimeout(() => {
      setClick(moveTo)
    }, 1000); 
  };
  const renderImageComponent = () => {
    switch (click) {
      case 0:
      case 1:
        return (
          <FirstImage setClick={setClick} click={click} handleClick={handleClick} />
        );
      case 2:
        return <SecondImage setClick={setClick} />;
      case 3:
        return <ThirdImage handleClick={handleClick} />;
      case 4:
        return <FourthImage setClick={setClick} />;
      case 5:
        return <FifthImage setClick={setClick} />;
      case 6:
        return <SixthImage sen={sen} getStarted={getStarted} />;
      default:
        return null;
    }
  };
  
  useEffect(() => {
    if (click === 6) {
      const timer = setTimeout(() => {
        setSen(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [click]); 


  return (
    <div className="Container">
      <Nav getStarted={getStarted}/>
      <MainTitle/>
      <SubTitle/>
      <GetStartedForm 
      handleSubmit={handleSubmit} 
      setUserEmail={setUserEmail}
      />
      <>
        {renderImageComponent()}
      </>
    </div>
  );
}

export default HeaderLayout;
