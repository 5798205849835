function IntegrationsSvg () {
    return(
        <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6769 6.64629V17.3537C14.6769 19.7048 17.6251 20.868 19.2465 19.2465C20.868 17.6251 19.7048 14.6769 17.3537 14.6769H6.64629C4.29519 14.6769 3.13204 17.6251 4.75346 19.2465C6.37489 20.868 9.32314 19.7048 9.32314 17.3537V6.64629C9.32314 4.29519 6.37489 3.13204 4.75346 4.75346C3.13204 6.37489 4.29519 9.32314 6.64629 9.32314H17.3537C19.7048 9.32314 20.868 6.37489 19.2465 4.75346C17.6251 3.13204 14.6769 4.29519 14.6769 6.64629Z"
          stroke="#254F1A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
}
export default IntegrationsSvg