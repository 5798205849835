function FourthSection ({getStarted}) {
    return(
        <div className="forthDiv">
          <p className="forthDiv-P">
            Built and designed to be fast, reliable and easy to use.
          </p>
          <p className="forthDiv-P2">
            Get started by creating an account and setting up your to collect
            submissions in just 2 minutes.
          </p>
          <button className="forthDiv-button" onClick={getStarted}>
            Get started
          </button>
        </div>
    )
}
export default FourthSection