function Arrow (){
    return(
        <svg
        className="arrow-img"
        viewBox="0 0 114 108"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2671_6020)">
          <path
            className="squiggle"
            fill="none"
            stroke="black"
            strokeMiterlimit="1"
            strokeWidth="1"
            clipPath="url(#clip0_2671_6020)"
            d="M56.1484 39.2531C40.2917 40.6271 28.3851 55.0637 24.1085 77.8131C27.7814 75.9113 28.1824 69.9665 33.2283 70.4573C33.634 71.0455 34.1081 71.4676 34.0635 71.7019C31.4486 77.5317 28.9257 83.4059 26.0945 89.069C25.9063 89.5363 25.5994 89.9464 25.2041 90.2588C24.8089 90.5711 24.3389 90.7748 23.8407 90.8498C23.3425 90.9247 22.8334 90.8683 22.3637 90.6861C21.894 90.5039 21.4801 90.2022 21.1627 89.8109C17.8724 86.0326 14.369 82.3155 12.307 76.5616L18.4524 77.9069C20.2559 71.4513 21.4686 64.8884 23.8283 58.7656C29.3896 44.3656 39.5478 34.8977 55.2447 32.4017C57.7307 32.0131 58.8062 30.7391 59.9778 28.7595C62.1698 24.7869 65.3094 21.4174 69.1173 18.9504C72.9253 16.4834 77.2837 14.9954 81.8053 14.6186C85.2957 14.2613 89.1259 14.07 92.0955 18.6428C89.2521 17.8547 86.2812 17.6352 83.3528 17.9967C80.4244 18.3583 77.5961 19.2939 75.0298 20.7499C70.0279 23.4715 65.8458 26.9713 63.7866 32.3173C67.8531 35.5795 72.114 38.2285 75.3329 41.8025C78.5957 45.4661 81.2571 49.6238 83.218 54.1208C84.1333 56.124 84.3044 58.388 83.7004 60.5061C83.0965 62.6241 81.7572 64.4574 79.923 65.6767C76.4517 68.0957 72.0062 67.8457 67.9938 64.6252C61.9043 59.6976 57.9204 52.6356 56.8522 44.8753C56.4786 42.998 56.352 41.1187 56.1484 39.2531ZM61.7305 39.5435C61.6465 43.2696 62.3621 46.9703 63.829 50.3965C65.2958 53.8227 67.4799 56.8948 70.2341 59.4057C71.9983 61.0847 74.1147 62.4746 76.4744 60.6151C78.4932 59.0169 78.0072 56.7045 77.0881 54.7515C73.9178 48.0275 68.4601 42.6496 61.6901 39.5787L61.7305 39.5435Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_2671_6020">
            <rect
              width="109.089"
              height="47.5432"
              fill="white"
              transform="translate(114 36) rotate(138.949)"
            />
          </clipPath>
        </defs>
      </svg>
    );

}
export default Arrow