import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Providers from "./services/providers";
import Login from "./views/login/Login";
import Dashboard from "views/Dashboard/Dashboard";
import ProjectDetails from "views/project/ProjectDetails/ProjectDetails";
import FormDetails from "views/submitionForm/submitionFormDetails";
import GlobalStyle from "./GlobalStyles";
import profileRoutes from "./views/Account/routes";

import ForgotPassForm from "views/login/ForgotPassForm";
import HomePage from "views/Home/HomePage";
import { RequireAdminAuth, RequireAuth } from "services/auth";
import CrerateAcc from "views/login/CrerateAcc";
import Exports from "views/Exports/Exports";
import Integrations from "views/Integrations/Integrations";
import Verify from "views/login/emailVerification/Verify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ResetPassword from "views/login/ResetPassword";
import AcceptEmail from "views/login/emailVerification/AcceptEmail";
import AllProjects from "views/project/AllProjects";
import LoginForm from "views/Admin/LoginForm";
import AdminDashboard from "views/Admin/Dashboard";
import Users from "views/Admin/Users/Users";
import Forms from "views/Admin/Forms/Forms";
import Settings from "views/Admin/Settings/Settings";
import apiRoutes from "views/api/routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Providers>
    <GlobalStyle />
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <GoogleOAuthProvider clientId="600180519562-3uun8j6pi8rkrj1jq67ffn0fjjcsuelo.apps.googleusercontent.com">
              <App />
            </GoogleOAuthProvider>
          }
        >
          <Route index element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/adminLogin" element={<LoginForm />} />
          <Route path="/accept" element={<AcceptEmail />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/adminDashboard"
            element={
              <RequireAdminAuth>
                <AdminDashboard />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/forms"
            element={
              <RequireAdminAuth>
                <Forms />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/integrations"
            element={
              <RequireAuth>
                <Integrations />
              </RequireAuth>
            }
          />
          <Route
            path="/projects"
            element={
              <RequireAuth>
                <AllProjects />
              </RequireAuth>
            }
          />
          <Route
            path="/projects/:id"
            element={
              <RequireAuth>
                <ProjectDetails />
              </RequireAuth>
            }
          />
          <Route
            path="project/:projectId/form/:formId"
            element={
              <RequireAuth>
                <FormDetails />
              </RequireAuth>
            }
          />
          <Route
            path="/users"
            element={
              <RequireAdminAuth>
                <Users />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/settings"
            element={
              <RequireAdminAuth>
                <Settings />
              </RequireAdminAuth>
            }
          />
          <Route path="/assets/*" element={null} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/forgot-password" element={<ForgotPassForm />} />
          <Route path="/create-account" element={<CrerateAcc />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/reset" element={<ResetPassword />} />
          {profileRoutes}
          {apiRoutes}
        </Route>
      </Routes>
    </BrowserRouter>
  </Providers>
);
reportWebVitals();
