// import img8 from "assets/webContentImg2.svg";
import '../header.css'

function SixthImage ({sen,getStarted}) {
    return(
        <div className="header-img-container">
          <img className="content-img1" src={"https://forms-easy.com/assets/webContentImg2.svg"} alt="form`s control" />
          <div className={sen?"div3":"hidden"}>
            <h3>🤔 Hmm...Look at that.</h3>
            <p>Looks like you want to see more.</p>
            <button 
            onClick={getStarted}
            className="clickable">Let’s Get Started</button>
          </div>
        </div>
    )
}
export default SixthImage;