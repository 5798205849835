import { useState } from "react";
import open from "assets/open.svg";
import close from "assets/close.svg";

const faqItems = [
    {
      id: "1",
      question: "Does form easy blocks spam submissions?",
      answer: "Yes, form easy has advanced in-built systems to block spam submissions."
    },
    {
      id: "2",
      question: "What is form endpoint?",
      answer: "A form endpoint or form backend, is a unique URL that will process your forms and performs the necessary actions to fulfill your needings (spam blocking, email sending, file uploads) in a reliable, fast and secure way. hnndes form is the best form endpoint in the industry."
    },
    {
      id: "3",
      question: "What is Forms easy?",
      answer: "Form easy is a form API (or endpoint) that allows you to collect submissions from your own designed HTML form without coding any backend, it basically serves as an form backend. By using Hnndes Form you will instantly access the best features such as getting email notifications for each new submission, file uploads, and more."
    },
    {
      id: "4",
      question: "Can I upload files from my form to Forms easy?",
      answer: "Yes, you can upload files with minimum effort to form easy."
    },
  ];

function SixthSection () {
      const [SelectedCard, setSelectedCard] = useState(null);
      const openDetail = (id) => {        
        setSelectedCard((prevSelectedCard) => (prevSelectedCard === id ? null : id));
      };
    
    return(

        <div className="sixDiv">
          <div className="sixDiv1">
            <p className="sixDiv1-P">Things, you probably wonder.</p>
          </div>
          <div className="sixDiv2">
          <ul>
            {faqItems.map((item) => (
              <li key={item.id} className="sixDiv2-li"onClick={() => openDetail(item.id)}>
                <div >
                  {item.question}
                  <img
                    style={{ float: "right" }}
                    src={SelectedCard === item.id ? close : open}
                    alt=""
                  />
                  <div className={SelectedCard === item.id ? "ldetails1" : "ldetails"}>
                    {item.answer}
                  </div>
                </div>
              </li>
            ))}
          </ul>
    </div>
        </div>
    )
}
export default SixthSection;